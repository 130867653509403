import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React from "react";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LoginMenu from "./api-authorization/MaterialLoginMenu";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { productionDomain, productionManageDomain } from "./common/Const";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  titleImg: {
    height: "25px",
    marginTop: "2px",
    marginLeft: "-5px",
    // [theme.breakpoints.up("md")]: {
    //   height: "55px",
    // },
    [theme.breakpoints.down("md")]: {
      height: "50px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "-10px",
    //   height: "26px",
    // },
    //[theme.breakpoints.down('sm')]: {
    //    height: "30px",
    //},
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    position: "fixed",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    //スマホ版navbarレイアウト調整中
    "& > div": {
      "& > a": {
        paddingRight: "2px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    //[theme.breakpoints.up("md")]: {
    //  display: "none",
    //},
  },
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit !important",
    "&:hover": {
      color: "inherit",
    },
  },
}));

export default function NavBar(props) {
  const { user } = props;
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useSafeState(unmountRef, null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useSafeState(unmountRef, null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNarrow = useMediaQuery(theme.breakpoints.down("md"));

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event) => {
  //     setMobileMoreAnchorEl(event.currentTarget);
  // };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit" size="large">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const domain = document.domain;

  return (
    // <div className={classes.grow}>
    //bottomNavigationテスト
    //<BottomNavigation
    //    value={value}
    //    onChange={(event, newValue) => {
    //        setValue(newValue);
    //    }}
    //    showLabels
    //    className={classes.root}
    //>
    //    <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
    //    <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
    //    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    //</BottomNavigation>
    <Fragment>
      <AppBar id="header-appbar" position="static" className={classes.appBar} color="inherit">
        <Toolbar>
          <div className={classes.sectionMobile}>
            {/*<div>*/}
            {user && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={props.onDrawerMenuClick}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          {domain !== productionDomain ? (
            <>
              <Typography variant="body2" color="error" style={{ fontWeight: "bold" }}>
                デモ
              </Typography>
              <Link to={user ? "/mypage" : "/"}>
                <img
                  className={classes.titleImg}
                  style={{ verticalAlign: "middle", marginLeft: "8px" }}
                  src={isNarrow ? "/img/logo_xs.png" : "/img/logo.jpg"}
                  alt="WEB発注システム powered by IMURA"
                />
              </Link>
            </>
          ) : (
            <Link to={user ? "/mypage" : "/"}>
              <img
                className={classes.titleImg}
                src={isNarrow ? "/img/logo_xs.png" : "/img/logo.jpg"}
                alt="WEB発注システム powered by IMURA"
              />
            </Link>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <LoginMenu handleProfileMenuOpen={handleProfileMenuOpen} menuId={menuId}></LoginMenu>
          </div>
          <div className={classes.sectionMobile}>
            <LoginMenu handleProfileMenuOpen={handleProfileMenuOpen} menuId={menuId}></LoginMenu>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Fragment>
  );
}
