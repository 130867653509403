import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { Fragment, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ListItemIcon } from "@mui/material";

import { LayoutContext } from "pages/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  nested: {
    //'& a': {
    //    transition: '0.3s',
    //    '&:hover': {
    //        backgroundColor: fade(theme.palette.primary.light, 0.15),

    //    }
    //},
    //paddingLeft: theme.spacing(2),
    "& .subItems": {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(4),

      //左から右下線
      //textDecoration: "none",
      //paddingBottom: "3px",
      //color: "#000",
      //display: "block",

      //'&:after': {
      //    content: '',
      //    width: "0",
      //    transition: "all 0.3s ease",
      //    borderBottom: "3px solid #000",
      //    display: "block",
      //},

      //'&:hover::after': {
      //    width: "100%",
      //    borderBottom: "3px solid #000",
      //}
    },
  },
  icon: {
    color: (props) => (props.iconColor ? props.iconColor : ""),
    backgroundColor: (props) => (props.iconBgColor ? props.iconBgColor : ""),
  },
}));

function GenerateListItem(props) {
  const classes = useStyles(props.item);
  const item = props.item;

  return (
    <Fragment>
      <ListItem
        button
        key={item.key}
        component={item.component}
        to={item.to}
        onClick={item.onClick}
      >
        {item.icon &&
          item.isAvatar && ( //条件付きレンダー
            <ListItemAvatar>
              <Avatar className={classes.icon}>{item.icon}</Avatar>
            </ListItemAvatar>
          )}
        {item.icon && !item.isAvatar && (
          <ListItemIcon className={classes.icon}>
            {
              item.icon //条件付きレンダー
            }
          </ListItemIcon>
        )}
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText ? item.secondaryText : null}
          className={"subItems"}
          primaryTypographyProps={item.primaryTypographyProps ? item.primaryTypographyProps : null}
          secondaryTypographyProps={
            item.secondaryTypographyProps ? item.secondaryTypographyProps : null
          }
        />
        {/* <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                    </ListItemSecondaryAction> */}
      </ListItem>
      {item.subItems && (
        <List className={classes.nested}>
          <GenerateListItems items={item.subItems} />
        </List>
      )}
    </Fragment>
  );
}

function GenerateListItems(props) {
  const items = props.items;
  return items.map((item) => <GenerateListItem item={item} key={item.key} />);
}

/**
 * リストを表示するコンポーネントです。
 * ネスト、アイコン表示、リンク設定が可能です。
 * @module InteractiveList
 * @example
 * const listItems = [
 *     {
 *         primaryText: "商品検索",
 *         secondaryText: "",
 *         key: "商品検索",
 *         icon: <Search />,
 *         iconColor: iconColor,
 *         iconBgColor: iconBgColor,
 *         subItems:
 *             [
 *                 {
 *                     primaryText: "別製品",
 *                     key: "特注品",
 *                     component: Link,
 *                     to: "/mypage/custom-products/search",
 *                 },
 *                 {
 *                     primaryText: "既製品",
 *                     key: "既製品",
 *                     component: Link,
 *                     to: "/mypage/standard-products/search",
 *                 },
 *             ]
 *     },
 * ]
 * return (
 *     <InteractiveList items={listItems}>
 *     </InteractiveList>
 * );
 * @param {any} props.items - リストに表示する内容
 *
 */
export default function InteractiveList(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const [dense /*, setDense*/] = useSafeState(unmountRef, false);
  const layoutCtx = useContext(LayoutContext);
  // const [secondary, setSecondary] = useSafeState(unmountRef, false);
  // console.log(props);
  return (
    <div className={classes.root} onClick={layoutCtx.ToggleMain}>
      {/* <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox checked={dense} onChange={(event) => setDense(event.target.checked)} />
                    }
                    label="Enable dense"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={secondary}
                            onChange={(event) => setSecondary(event.target.checked)}
                        />
                    }
                    label="Enable secondary text"
                />
            </FormGroup> */}
      {props.title && (
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
      )}
      {/* <Paper className={classes.listRoot}> */}
      <List dense={dense}>
        <GenerateListItems items={props.items} />
      </List>
      {/* </Paper> */}
    </div>
  );
}
