import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useCallback, useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Toolbar,
  Paper,
  useMediaQuery,
} from "@mui/material/";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Login from "components/api-authorization/Login";
import { LoginActions } from "components/api-authorization/ApiAuthorizationConstants";
import { commonStyle } from "components/styles/CommonStyle";
import { useForm } from "react-hook-form";
import {
  ErrMsgKey,
  GetErrMsg,
  ErrMsg,
  useErrorMessageProvider,
} from "components/common/ErrorMessageProvider";
import ScrollToTopOnMount from "components/behavior/Scroll";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { localStorageKeys, sessionStorageKeys } from "components/common/Const";
import { PageTitle } from "components/common/PageTitle";
import { ApiPostLogin } from "components/api/LoginApi";
import { UserContext } from "components/api-authorization/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  box: {
    textAlign: "center",
    margin: theme.spacing(3),
  },
}));

export default function LoginFormContainer(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Toolbar></Toolbar>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Paper style={{ padding: "20px", textAlign: "center" }}>
            <LoginForm></LoginForm>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export function LoginForm(props) {
  const unmountRef = useUnmountRef();
  const userctx = useContext(UserContext);
  const [isSuccess, setIsSuccess] = useSafeState(unmountRef);
  const { setValidationErrorsForaxios } = useErrorMessageProvider();
  const theme = useTheme();
  const isSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [submitDisabled, setSubmitDisabled] = useSafeState(unmountRef, false); //submitボタンの活性制御

  const history = useHistory();

  //入力チェックのスキーマ定義
  const [schemaData, setSchemaData] = useSafeState(unmountRef, {
    loginId: yup.string().required(GetErrMsg(ErrMsgKey.required)),
    loginPwd: yup.string().required(GetErrMsg(ErrMsgKey.required)),
  });
  const schema = yup.object().shape(schemaData);
  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // window.ReactNativeWebView.postMessage("hoge"); //ReactNativeに値を渡す
  }, []);

  const onSubmit = useCallback(async (data, e) => {
    // ボタン非活性
    setSubmitDisabled(true);

    // リビジョン情報以外のセッションストレージを消す
    Object.keys(sessionStorageKeys).map((item, index) => {
      if (sessionStorageKeys[item] !== sessionStorageKeys.Revision)
        sessionStorage.removeItem(sessionStorageKeys[item]);
    });

    let json = {
      loginid: data.loginId,
      loginPwd: data.loginPwd,
      isRememberMe: data.isRememberMe,
    };

    await ApiPostLogin(json)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.requiresTwoFactor) {
            history.push({
              pathname: "/login2fa",
              state: { token: response.data.token },
            });
          } else {
            localStorage.setItem(localStorageKeys.LoginJWT, response.data.token);
            userctx.refreshUser();
            history.push("/mypage");
          }
        }
      })
      .catch(function (error) {
        setIsSuccess(false); //非同期なのでこの直後で呼び出しても変わってないことがある
        setSubmitDisabled(false);
        if (error.response.status === 400) setValidationErrorsForaxios(error, setError);
        else {
          setError("summary", {
            type: "manual",
            message: GetErrMsg(ErrMsgKey.unexpected),
          });
        }
      });
  });

  const handleKeyPressEnter = useCallback((e) => {
    if (e.key === "Enter") {
      clearErrors();
      handleSubmit(onSubmit);
    }
    // handleClick(e);
  });

  const classes = useStyles();
  const style = commonStyle();

  // let loginComponent = undefined;
  // if (isSuccess) {
  //   loginComponent = (
  //     <Login action={LoginActions.Login} key={new Date()}></Login>
  //   );
  // } else {
  //   loginComponent = (
  //     <div>
  //       <Login action={LoginActions.LoginFailed} key={new Date()}></Login>
  //     </div>
  //   );
  // }

  if (userctx.user) history.push("/mypage");

  return (
    <div>
      <PageTitle title="ログイン" />
      <form id="login-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.box}>
          <img src="/img/logo.jpg" style={{ height: "20px" }} alt="ロゴ" />
        </Box>

        <div style={{ textAlign: "center" }}>
          <AccountCircleIcon style={{ fontSize: 80, color: "#ddd" }}></AccountCircleIcon>
        </div>
        <Box className={classes.box}>
          {!isSuccess && errors["summary"] === undefined && (
            <div>
              <div>IDとパスワードを入力して</div>
              <div>「ログイン」をクリックしてください</div>
            </div>
          )}
          {/* {loginComponent} */}
          <ErrMsg className={style.errMsg} errors={errors} name="summary" />
        </Box>
        <Box className={classes.box}>
          <div style={{ textAlign: "center", padding: theme.spacing(0, 2) }}>
            <TextField
              fullWidth={isSizeXs}
              variant="standard"
              autoFocus
              margin="dense"
              name="loginId"
              label="ID"
              type="text"
              inputRef={register}
            />
            <ErrMsg className={style.errMsg} errors={errors} name="loginId" />
          </div>
          <div style={{ textAlign: "center", padding: theme.spacing(0, 2) }}>
            <TextField
              fullWidth={isSizeXs}
              variant="standard"
              id="pwd"
              margin="dense"
              name="loginPwd"
              label="パスワード"
              type="password"
              onKeyPress={handleKeyPressEnter}
              inputRef={register}
            />
            <ErrMsg className={style.errMsg} errors={errors} name="loginPwd" />
          </div>
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              control={<Checkbox name="isRememberMe" value={true} />}
              label="ログイン状態を保持する"
              inputRef={register}
            />
          </div>
        </Box>
        <Box className={classes.box}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => {
              clearErrors();
            }}
            disabled={submitDisabled}
          >
            ログイン
          </Button>
        </Box>
        <Box className={classes.box}>
          <Link to="auth/forgot">パスワードを忘れた方</Link>
        </Box>
      </form>
    </div>
  );
}
