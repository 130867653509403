/**
 * 得意先CDを0000000形式で返します
 * @param {*} id
 * @param {*} boid
 */
export function FormatCustomerId(id, boid) {
  if (id != null) return `${id.toString().padStart(4, "0")}${boid.toString().padStart(3, "0")}`;
  else return "";
}

/**
 * 別製品IDを0000000X000形式で返します
 * @param {*} cid
 * @param {*} boid
 * @param {*} itemid
 */
export function FormatCustomItemId(cid, boid, itemid) {
  if (cid != null && boid != null && itemid != null)
    return `${FormatCustomerId(cid, boid)}${itemid}`;
  else return "";
}
