import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";

/**
 * ユーザー設定xlsテンプレートを取得します
 * IEOS.Api.UsersController.cs GetXlsTemplate
 * @param {*} token
 * @returns
 */
export async function ApiGetUserSettingXlsTemplate(token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  let headers = { "content-type": "multipart/form-data" };
  if (tkn) headers.Authorization = `Bearer ${tkn}`;

  return await axios.get("api/users/xls/template", {
    headers: headers,
    responseType: "arraybuffer",
  });
}
/**
 * ユーザー設定Xlsファイルをアップロードします
 * IEOS.Api.UsersConteroller.cs PostXls
 * @param {*} files - e.target.filesをそのまま入れてください
 * @param {*} token
 * @returns
 */
export async function ApiPostUsersSettingsXls(files, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  let headers = { "content-type": "multipart/form-data" };
  if (tkn) headers.Authorization = `Bearer ${tkn}`;

  const param = new FormData();
  param.append("file", files);

  return await axios.post(`api/users/xls`, param, {
    headers: headers,
    responseType: "arraybuffer",
    // headers: !token ? {} : { Authorization: `Bearer ${token}` },
  });
}

export async function ApiPutUsersCustomer(cid, bid, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  var obj = { cid: cid, bid: bid };
  return await axios.put(`api/users/changecustomer`, obj, {
    headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
  });
}
