/**
 * @file 2段階認証/2要素認証 ログイン画面
 */
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Toolbar,
  Paper,
} from "@mui/material/";
import { useHistory, useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import LockIcon from "@mui/icons-material/Lock";
import Login from "components/api-authorization/Login";
import { LoginActions } from "components/api-authorization/ApiAuthorizationConstants";
import { commonStyle } from "components/styles/CommonStyle";
import { useForm } from "react-hook-form";
import {
  ErrMsgKey,
  GetErrMsg,
  ErrMsg,
  useErrorMessageProvider,
} from "components/common/ErrorMessageProvider";
import ScrollToTopOnMount from "components/behavior/Scroll";
// import { FormDataToJson } from 'components/common/FormHelper';
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { localStorageKeys, sessionStorageKeys } from "components/common/Const";
import { Helmet } from "react-helmet";
import { UserContext } from "components/api-authorization/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  box: {
    textAlign: "center",
    margin: theme.spacing(3),
  },
}));

export default function Login2faForm(props) {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ScrollToTopOnMount />
      <Toolbar></Toolbar>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Paper style={{ padding: "20px", textAlign: "center" }}>
            <Login2fa></Login2fa>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export function Login2fa(props) {
  const unmountRef = useUnmountRef();
  const [isSuccess, setIsSuccess] = useSafeState(unmountRef);
  const { setValidationErrorsForaxios } = useErrorMessageProvider();
  const history = useHistory();
  const location = useLocation();
  const userctx = useContext(UserContext);

  const [submitDisabled, setSubmitDisabled] = useSafeState(unmountRef, false); //submitボタンの活性制御

  //入力チェックのスキーマ定義
  const [schemaData, setSchemaData] = useSafeState(unmountRef, {
    loginCode: yup.string().required(GetErrMsg(ErrMsgKey.required)),
  });
  const schema = yup.object().shape(schemaData);
  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback((data, e) => {
    // ボタン非活性
    setSubmitDisabled(true);

    // リビジョン情報以外のセッションストレージを消す
    Object.keys(sessionStorageKeys).map((item, index) => {
      if (sessionStorageKeys[item] !== sessionStorageKeys.Revision)
        sessionStorage.removeItem(sessionStorageKeys[item]);
    });

    let json = {
      loginCode: data.loginCode,
      tokenStr: location.state["token"],
      isRememberMe: data.isRememberMe,
    };
    axios
      .post("api/login/login2fa", json)
      .then(function (response) {
        if (response.status === 200) {
          setIsSuccess(true);
          localStorage.setItem(localStorageKeys.LoginJWT, response.data.token);
          userctx.refreshUser();
          history.push("/mypage");
        }
      })
      .catch(function (error) {
        console.log("error");
        setIsSuccess(false); //非同期なのでこの直後で呼び出しても変わってないことがある
        setSubmitDisabled(false);
        if (error.response.status === 400) setValidationErrorsForaxios(error, setError);
        else {
          setError("summary", {
            type: "manual",
            message: GetErrMsg(ErrMsgKey.unexpected),
          });
        }
      });
  });

  const handleKeyPressEnter = useCallback((e) => {
    if (e.key === "Enter") {
      clearErrors();
      handleSubmit(onSubmit);
    }
    // handleClick(e);
  });

  const classes = useStyles();
  const style = commonStyle();

  if (userctx.user) history.push("/mypage");

  // let loginComponent = undefined;
  // if (isSuccess) {
  //   loginComponent = (
  //     <Login action={LoginActions.Login} key={new Date()}></Login>
  //   );
  // } else {
  //   loginComponent = (
  //     <div>
  //       <Login action={LoginActions.LoginFailed} key={new Date()}></Login>
  //     </div>
  //   );
  // }

  return (
    <div>
      <form id="login2fa-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.box}>
          <img src="/img/logo.jpg" style={{ height: "40px" }} alt="ロゴ" />
        </Box>

        <div style={{ textAlign: "center" }}>
          <LockIcon style={{ fontSize: 80, color: "#ddd" }}></LockIcon>
        </div>
        <Box className={classes.box}>
          {!isSuccess && errors["summary"] === undefined && (
            <div>
              <div>メールで送信された認証コードを入力して</div>
              <div>「ログイン」をクリックしてください</div>
            </div>
          )}
          {/* {loginComponent} */}
          <ErrMsg className={style.errMsg} errors={errors} name="summary" />
        </Box>
        <Box className={classes.box}>
          <div style={{ textAlign: "center" }}>
            <TextField
              id="code"
              margin="dense"
              name="loginCode"
              label="認証コード"
              type="password"
              onKeyPress={handleKeyPressEnter}
              inputRef={register}
            />
            <ErrMsg className={style.errMsg} errors={errors} name="loginCode" />
          </div>
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              control={<Checkbox name="isRememberMe" value={true} />}
              label="ログイン状態を保持する"
              inputRef={register}
            />
          </div>
        </Box>
        <Box className={classes.box}>
          <Button
            style={{ width: "220px" }}
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => {
              clearErrors();
            }}
            disabled={submitDisabled}
          >
            ログイン
          </Button>
        </Box>
        <Box className={classes.box}>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            戻る
          </Button>
        </Box>
      </form>
    </div>
  );
}
