import React, { useCallback } from "react";
import { Tooltip } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  reloadIcon: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      borderRadius: "50%",
    },
  },
  loading: {
    "&": {
      display: "none",
    },
  },
}));

/**
 * 最新の情報に更新するボタン
 * @param {Object} props - 基本的にはButtonのpropsに準じます
 * @param {bool} props.refreshTable - 検索等で、データが変わるときに呼び出しもとで変更する。
 */
export default function ReloadButton(props) {
  // const classes = useStyles();
  const { refreshTable, isLoading, setPageRefreshTable } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Tooltip title="最新の情報に更新">
      <ReplayIcon
        className={isLoading ? classes.loading : classes.reloadIcon}
        style={{
          verticalAlign: "middle",
          transform: "scaleX(-1) rotate(-30deg) ",
          padding: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
        }}
        onClick={() => setPageRefreshTable(!refreshTable)}
        variant="contained"
        refreshTable={refreshTable}
        color="primary"
      />
    </Tooltip>
  );
}
