import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import { useEffect } from "react";

/**
 * 定期実行するタイマーです
 * @param {Object} props
 * @param {number} props.interval - 定期実行する間隔ミリ秒単位
 */
export default function useTimer(interval) {
  const unmountRef = useUnmountRef();
  const [time, setTime] = useSafeState(unmountRef);

  useEffect(() => {
    const timeoutId = setTimeout(() => setTime(Date.now()), interval);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [time]);

  return time;
}
