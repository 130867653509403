import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";

/**
 * ファイルを取得します。
 * @param {*} containerName - コンテナ名
 * @param {*} fileName - ファイル名
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetFile(containerName, fileName, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();

  return await axios.get(`api/azurestorage?containerName=${containerName}&fileName=${fileName}`, {
    headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
  });
}

/**
 * ファイルを保存します。
 * @param {*} containerName - コンテナ名
 * @param {*} fileName - ファイル名
 * @param {*} files - ファイル
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiPutFile(containerName, fileName, files, contentType, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();

  var values = new FormData();
  values.append("containerName", containerName);
  values.append("fileName", fileName);
  if (contentType) values.append("contentType", contentType);

  files.forEach((imageData) => {
    values.append("files", imageData.blob);
  });

  return await axios.put(`api/azurestorage`, values, {
    headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
  });
}

/**
 * 画像を削除します。
 * @param {*} containerName - コンテナ名
 * @param {*} fileName - ファイル名
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiDeleteFile(containerName, fileName, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();

  return await axios.delete(
    `api/azurestorage?containerName=${containerName}&fileName=${fileName}`,
    {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    }
  );
}

/**
 * クライアント側で利用するAzureStorageのConfigを取得します。
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetConfig(token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();

  return await axios.get(`api/azurestorage/config`, {
    headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
  });
}
