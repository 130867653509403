import React from "react";
import clsx from "clsx";
import { IconButton, Badge, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  iconButtonContainer: {},
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  iconButtonText: {
    fontSize: "11px",
  },
}));

export default function IconTextButton(props) {
  const classes = useStyles(props);
  const { text, badgeContent, badgeColor, className, color, ...other } = props;
  return (
    <div className={classes.iconButtonContainer}>
      <IconButton
        className={clsx(classes.iconButtonLabel, className)}
        color={color}
        {...other}
        size="large"
      >
        <Badge badgeContent={badgeContent} color={badgeColor}>
          {props.children}
        </Badge>
        <Typography noWrap className={classes.iconButtonText}>
          {text}
        </Typography>
      </IconButton>
    </div>
  );
}
