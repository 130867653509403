import "./App.css";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import axios from "axios";
import AppRouter from "./AppRouter";

import { GetRevision } from "components/api/CheckRevisionApi";
import { sessionStorageKeys } from "components/common/Const";
import { MessageContext } from "components/context/MessageContext";
import "react-app-polyfill/stable";
import "custom.css";
// import "css/bootstrap.css";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";

let theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          fontSize: "1.3rem",
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    palette: {
      primary: {
        main: "rgba(0,80,164,1)",
      },
      secondary: {
        main: "#EB5917",
      },
    },
  })
);

theme = responsiveFontSizes(theme);

export default function App() {
  const unmountRef = useUnmountRef();
  // const displayName = App.name;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const [closeSnackbarWhenlocationChange, setCloseSnackbarWhenlocationChange] = useSafeState(
    unmountRef,
    []
  );

  window.onbeforeunload = (event) => {
    // // リロードされたときに、バージョン等の情報を初期化する
    // var setRevision = async () => {
    //   await GetRevision().then((revision) => {
    //     sessionStorage.setItem(sessionStorageKeys.Revision, revision);
    //   });
    // };
    // // バージョンを更新
    // setRevision();
    sessionStorage.removeItem(sessionStorageKeys.Revision);
    // // リロードメッセージを削除
    // sessionStorage.removeItem(sessionStorageKeys.Reload);
    // // サーバーバージョンを削除
    // sessionStorage.removeItem(sessionStorageKeys.ServerRevision);
  };

  useEffect(() => {
    //axiosでキャッシュしない設定
    //IE11でaxiosがキャッシュする動作の対応
    axios.defaults.headers["Pragma"] = "no-cache";
    sessionStorage.setItem(sessionStorageKeys.Revision, "init");

    var setRevisionFn = async () => {
      await GetRevision().then((revision) => {
        sessionStorage.setItem(sessionStorageKeys.Revision, revision);
      });
    };
    // バージョンを更新
    setRevisionFn();
  }, []);

  useEffect(() => {
    if (closeSnackbarWhenlocationChange.length > 0) {
      closeSnackbarWhenlocationChange.forEach((key, index) => {
        closeSnackbar(key);
      });
      setCloseSnackbarWhenlocationChange([]);
    }
  }, [location]);

  return (
    <MessageContext.Provider
      value={{
        enqueue: (message, type) => {
          // setSnackbarClose(true);
          var key = enqueueSnackbar(message, {
            variant: type,
          });
          closeSnackbarWhenlocationChange.push(key);
          setCloseSnackbarWhenlocationChange(closeSnackbarWhenlocationChange);
        },
        enqueueNotClose: (message, type) => {
          enqueueSnackbar(message, {
            variant: type,
          });
        },
        // enqueueWithOption: (message, option) => {
        //   var key = enqueueSnackbar(message, option);
        //   return key;
        // },
        closeSnackbar: (key) => {
          if (key) closeSnackbar(key);
        },
        enqueueWithOption: (message, option, isLocationClose) => {
          var key = enqueueSnackbar(message, option);
          if (isLocationClose) {
            closeSnackbarWhenlocationChange.push(key);
            setCloseSnackbarWhenlocationChange(closeSnackbarWhenlocationChange);
          }
          return key;
        },
      }}
    >
      <GoogleReCaptchaProvider reCaptchaKey="6LfssZ8aAAAAALIn07npjfyeIU95nqAMbs2QOV4G">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route path="/" component={AppRouter} />
            </Switch>
          </ThemeProvider>
        </StyledEngineProvider>
      </GoogleReCaptchaProvider>
    </MessageContext.Provider>
  );
}
