/**
 * Form内の入力データをJsonにして返します。
 * @param {string} formName - formのid
 * @returns Jsonオブジェクト
 */
export function FormDataToJson(formName) {
  const formData = new FormData(document.getElementById(formName));
  //FormDataから値のみ抽出する
  var object = {};
  formData.forEach(function (value, key) {
    object[key] = value;
  });

  return object;
}
