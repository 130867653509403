import withStyles from "@mui/styles/withStyles";
import { TableCell } from "@mui/material";

export const TableHeaderCell = withStyles((theme) => ({
  head: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}))(TableCell);
