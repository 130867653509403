import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import App from "App";
import "custom.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import "react-app-polyfill/stable";
import { Helmet } from "react-helmet";
import { productionDomain } from "components/common/Const";
import NotistackCloseButton from "components/input/button/NotistackCloseButton";

const useStyles = makeStyles((theme) => ({
  containerRoot: { top: "65px !important" },
  variantWarning: { color: "#575757 !important" },
}));

export default function WrapApp() {
  const classes = useStyles();
  const domain = document.domain;

  return (
    <SnackbarProvider
      id="snackbar-provider"
      maxSnack={5}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={5000}
      classes={{
        containerRoot: classes.containerRoot,
        variantWarning: classes.variantWarning,
      }}
      //閉じるボタンを追加
      action={(key) => <NotistackCloseButton snackbarKey={key} />}
    >
      {domain !== productionDomain ? (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          {/* デモ用 GoogleAnalytics */}
          {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-4SWCE1ST0L"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());

              gtag('config', 'G-4SWCE1ST0L');
              `}
          </script>
        </Helmet>
      ) : (
        <Helmet>
          {/* 本番用 GoogleAnalytics */}
          {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
          <meta name="robots" content="noindex, nofollow" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-HZG0E360QZ"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-HZG0E360QZ');
              `}
          </script>
        </Helmet>
      )}
      <App />
    </SnackbarProvider>
  );
}
