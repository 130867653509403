import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";

/**
 * 顧客設定の情報を取得します
 * @param {*} cid - カスタマーID
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetCustomerSetting(cid, branchOfficeId, token) {
  let result;
  if (!token) token = await authService.getAccessToken();
  await axios
    .get(`api/customerSettings?customerId=${cid}&branchOfficeId=${branchOfficeId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 顧客設定の情報(親子関係/得意先設定)を取得します
 * @param {*} cid - カスタマーID
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetCustomerSettingDetail(cid, branchOfficeId, token) {
  let result;
  if (!token) token = await authService.getAccessToken();
  await axios
    .get(`api/customerSettings/detail?customerId=${cid}&branchOfficeId=${branchOfficeId}`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 顧客設定を登録・更新します
 * @param {Json} values - 値のJSON文字列
 * @param {String} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiPostCustomerSetting(values, token) {
  if (!token) token = await authService.getAccessToken();
  return await axios.post(`api/customerSettingsManage`, values, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  });
}

export async function ApiPatchSettingByCustomer(values, token) {
  if (!token) token = await authService.getAccessToken();
  return await axios.patch(`api/customerSettings`, values, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  });
}

/**
 * 製品の表示非表示情報を格納したExcelファイルをアップロードします
 * IEOS.Manage.CustomProductsController.cs/customproductvisiblePerUserxls
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiPostCustomProductVisiblePerUserXls(files, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  let headers = { "content-type": "multipart/form-data" };
  if (tkn) headers.Authorization = `Bearer ${tkn}`;

  const param = new FormData();
  param.append("file", files);
  return await axios.post(`api/customerSettingsManage/xls`, param, {
    headers: headers,
    responseType: "blob",
  });
}

/**
 * 選択したユーザーに紐づく製品の表示非表示情報を格納したExcelファイルを取得します
 * IEOS.Manage.CustomProductsController.cs/customproductvisiblePerUserxls
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiGetCustomProductVisiblePerUserXls(object, token) {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();

  return await axios.post(`api/customerSettingsManage/customproductvisiblePerUsersxls`, object, {
    headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    responseType: "blob",
  });
}
