import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useContext, useEffect, lazy, Suspense } from "react";
import withStyles from "@mui/styles/withStyles";
import { commonStyle, FloatingBackgroundGlobalCss } from "components/styles/CommonStyle";
import AuthorizeRoute from "components/api-authorization/AuthorizeRoute";
import ClaimAuthorizeRoute from "components/api-authorization/ClaimAuthorizeRoute";
import { CircularProgress, Container, Toolbar, useTheme } from "@mui/material";
import ClippedDrawer from "components/drawer/ClipedDrawer";
import {
  RecentActors,
  Assignment,
  Notifications,
  Home,
  Settings,
  ExitToApp,
  MailOutline,
  PostAdd,
  Web,
  Search,
  People,
  Storage,
  LocalShipping,
  NoteAdd,
  HowToReg,
  ThumbUp,
  ContactMail,
  GroupWork,
  Person,
  Factory,
} from "@mui/icons-material";
import { Link, Switch } from "react-router-dom";
import { ApplicationPaths, Claims } from "components/api-authorization/ApiAuthorizationConstants";
import { UserContext } from "components/api-authorization/UserContext";
import { LayoutContext } from "pages/Layout";

import CheckRevisionComponent from "components/common/CheckRevisionComponent";
import useReactRouter from "use-react-router";
import { ContactModal } from "components/common/Contact";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { BillingModal } from "components/common/BillingModal";
// import ProductRoute from "pages/product/ProductRoute";
// import {MyPageRoute} from "pages/mypage/MyPageRoute";
// import {ManageRoute} from "pages/manage/ManageRoute";
// import AdminRoute from "pages/admin/AdminRoute";
// import ShoppingRoute from "pages/shopping/ShoppingRoute";

// 遅延読込
const ProductRoute = lazy(() => import("pages/product/ProductRoute"));
const MyPageRoute = lazy(() => import("pages/mypage/MyPageRoute"));
const UserRoute = lazy(() => import("pages/user/UserRoute"));
const ManageRoute = lazy(() => import("pages/manage/ManageRoute"));
// const AdminRoute = lazy(() => import("pages/admin/AdminRoute"));
const StockRoute = lazy(() => import("pages/stock/StockRoute"));
// const PriceRoute = lazy(() => import("pages/price/PriceRoute"));
const InquiryRoute = lazy(() => import("pages/inquiry/InquiryRoute"));

export function AuthLayout(props) {
  const unmountRef = useUnmountRef();
  const layoutCtx = useContext(LayoutContext);

  const userctx = useContext(UserContext);
  const theme = useTheme();
  const styles = commonStyle();

  const [listItems, setListItems] = useSafeState(unmountRef, []);
  const [contactOpen, setContactOpen] = useSafeState(unmountRef, false);
  const [billingOpen, setBillingOpen] = useSafeState(unmountRef, false);
  const { history } = useReactRouter();

  const GetMenu = () => {
    var searchSubItemsArr = [];
    var inquiryArr = [];
    var customerSettings = userctx && userctx.customerSettings;

    if (customerSettings) {
      if (customerSettings.isUseCustomProductList)
        searchSubItemsArr.push({
          primaryText: "別製品",
          key: "特注品",
          component: Link,
          to: "/product/custom/search",
        });

      if (customerSettings.isUseStandardProductList)
        searchSubItemsArr.push({
          primaryText: "既製品",
          key: "既製品",
          component: Link,
          to: "/product/standard/search",
        });

      if (customerSettings.isUseStockList && userctx && userctx.hasClaim(Claims.StocksList_Read))
        searchSubItemsArr.push({
          primaryText: "在庫品",
          key: "在庫品",
          component: Link,
          to: "/stock/list",
        });

      // if (customerSettings.isUseCustomProductOrderHistory)
      //   inquiryArr.push({
      //     primaryText: <span style={{ whiteSpace: "nowrap" }}>別製品受注履歴</span>,
      //     key: "別製品受注履歴",
      //     component: Link,
      //     to: "/inquiry/order/history",
      //   });

      if (customerSettings.isUseStandardStockInquiry)
        inquiryArr.push({
          primaryText: <span style={{ whiteSpace: "nowrap" }}>既製品在庫情報</span>,
          key: "既製品在庫情報",
          component: Link,
          to: "/inquiry/standardstock",
        });

      // if (customerSettings.isUseBillingInquiry)
      //   inquiryArr.push({
      //     primaryText: <span style={{ whiteSpace: "nowrap" }}>ご請求情報</span>,
      //     key: "ご請求情報",
      //     // component: Link,
      //     onClick: () => {
      //       setBillingOpen(true);
      //     },
      //   });
    }

    var list = [];
    list.push({
      primaryText: "Myページ",
      secondaryText: "",
      key: "Myページ",
      icon: <Home />,
      // iconColor: iconColor,
      // iconBgColor: iconBgColor,
      component: Link,
      to: `/mypage/`,
    });
    if (searchSubItemsArr && searchSubItemsArr.length > 0)
      list.push({
        primaryText: "商品検索",
        secondaryText: "",
        key: "商品検索",
        icon: <Search />,
        subItems: searchSubItemsArr,
        // userctx && userctx.hasClaim(Claims.StocksList_Read)
        //   ? [
        //       {
        //         primaryText: "別製品",
        //         key: "特注品",
        //         component: Link,
        //         to: "/product/custom/search",
        //       },
        //       {
        //         primaryText: "既製品",
        //         key: "既製品",
        //         component: Link,
        //         to: "/product/standard/search",
        //       },
        //       {
        //         primaryText: "在庫品",
        //         key: "在庫品",
        //         component: Link,
        //         to: "/stock/list",
        //       },
        //     ]
        //   : [
        //       {
        //         primaryText: "別製品",
        //         key: "特注品",
        //         component: Link,
        //         to: "/product/custom/search",
        //       },
        //       {
        //         primaryText: "既製品",
        //         key: "既製品",
        //         component: Link,
        //         to: "/product/standard/search",
        //       },
        //     ],
      });

    // list.push({
    //   primaryText: "在庫出荷依頼",
    //   secondaryText: "",
    //   key: "在庫出荷依頼",
    //   icon: <LocalShipping />,
    //   component: Link,
    //   to: "/mypage/stock",
    // });

    // list.push({
    //   primaryText: "在庫作成依頼",
    //   secondaryText: "",
    //   key: "在庫作成依頼",
    //   icon: <FontAwesomeIcon icon={faBox} />,
    //   component: Link,
    //   to: "/mypage/stock",
    // });

    if (customerSettings && customerSettings.isUseOrderList)
      list.push({
        primaryText: "注文一覧",
        secondaryText: "",
        key: "注文一覧",
        icon: <Assignment />,
        component: Link,
        to: "/mypage/history",
      });

    // list.push({
    //   primaryText: "在庫品一覧",
    //   secondaryText: "",
    //   key: "在庫品一覧",
    //   icon: <Storage />,
    //   component: Link,
    //   to: "/stock/list",
    // });
    if (
      userctx &&
      userctx.hasClaim(Claims.StocksShippingHistory_Read) &&
      customerSettings &&
      customerSettings.isUseStockShippingList
    ) {
      list.push({
        primaryText: "在庫出荷一覧",
        secondaryText: "",
        key: "在庫出荷一覧",
        icon: <LocalShipping />,
        component: Link,
        to: "/stock/shipping/history",
      });
    }

    if (
      userctx &&
      userctx.hasClaim(Claims.StocksCreateHistory_Read) &&
      customerSettings &&
      customerSettings.isUseStockCreateList
    ) {
      list.push({
        primaryText: "在庫作成一覧",
        secondaryText: "",
        key: "在庫作成一覧",
        icon: <Factory />,
        component: Link,
        to: "/stock/create/history",
      });
    }

    if (inquiryArr && inquiryArr.length > 0) {
      list.push({
        primaryText: "照会",
        secondaryText: "",
        key: "照会",
        icon: <ManageSearchIcon />,
        subItems: inquiryArr,
      });
    }

    if (userctx && userctx.hasClaim(Claims.User_Read)) {
      list.push({
        primaryText: "ユーザー管理",
        secondaryText: "",
        key: "ユーザー管理",
        icon: <Person />,
        component: Link,
        to: "/user/users",
      });
    }

    // 得意先設定も見る必要があるかもしれない。
    if (
      userctx &&
      userctx.hasClaim(Claims.ApprovalSetting_Read) &&
      userctx.customerSettings &&
      userctx.customerSettings.isUseApproval
    ) {
      // console.log(userctx.user);
      list.push({
        primaryText: "承認設定",
        secondaryText: "",
        key: "承認設定",
        icon: <HowToReg />,
        // component: Link,
        // to: "/manage/approvalsetting",
        onClick: () => {
          history.push({
            pathname: `/manage/approvalsetting`,
          });
        },
      });
    }

    //グループ設定用の権限を設定する
    if (
      userctx &&
      userctx.hasClaim(Claims.Group_Read) &&
      userctx.customerSettings &&
      userctx.customerSettings.isUseApproval
    ) {
      list.push({
        primaryText: "グループ一覧",
        secondaryText: "",
        key: "グループ一覧",
        icon: <People />,
        // component: Link,
        // to: "/manage/approvalsetting",
        onClick: () => {
          history.push({
            pathname: `/manage/groupList`,
          });
        },
      });
    }

    // TODO お知らせとメール送信は管理者メニューに移動する
    // userctx.claims &&
    //   userctx.hasClaim(Claims.Information_Read) &&
    //   list.push({
    //     primaryText: "お知らせ",
    //     secondaryText: "",
    //     key: "お知らせ",
    //     icon: <Notifications />,
    //     iconColor: iconColor,
    //     iconBgColor: iconBgColor,
    //     component: Link,
    //     to: "/manage/information/informationList",
    //   });
    // userctx.claims &&
    //   userctx.hasClaim(Claims.Notices_Read) &&
    //   list.push({
    //     primaryText: "メッセージ送信",
    //     secondaryText: "",
    //     key: "メッセージ送信",
    //     icon: <MailOutline />,
    //     iconColor: iconColor,
    //     iconBgColor: iconBgColor,
    //     component: Link,
    //     to: "/manage/notices/index",
    //   });

    if (userctx && userctx.customerSettings && userctx.customerSettings.isUseOrderMail) {
      list.push({
        primaryText: "お問い合わせ",
        secondaryText: "",
        key: "お問い合わせ",
        icon: <ContactMail />,
        // component: Link,
        onClick: () => {
          setContactOpen(true);
        },
      });
    }

    if (userctx && userctx.hasClaim(Claims.Settings_Edit_For_Customer)) {
      list.push({
        primaryText: "設定",
        secondaryText: "",
        key: "設定",
        icon: <Settings />,
        // component: Link,
        onClick: () => {
          history.push({
            pathname: `/manage/settings`,
          });
        },
      });
    }
    // list.push({
    //   primaryText: "ログアウト",
    //   secondaryText: "",
    //   key: "ログアウト",
    //   icon: <ExitToApp />,
    //   component: Link,
    //   //to: "/manage/account/index",
    //   to: logoutPath,
    // });

    // userctx.claims &&
    //   userctx.hasClaim(Claims.Can_Use_Admin_Menu) &&
    //   list.push({
    //     primaryText: "システム管理",
    //     secondaryText: "",
    //     key: "管理者メニュー",
    //     icon: <Settings />,
    //     iconColor: iconColor,
    //     iconBgColor: iconBgColor,
    //     component: Link,
    //     to: "/admin/",
    //   });

    setListItems(list);
  };

  useEffect(() => {
    //アプリからのイベント呼出
    //Android
    document.addEventListener("message", (event) => {});
    //iOS
    window.addEventListener("message", function (e) {
      // alert(e.origin);
      // alert(e.source);
      // alert(e.target);
      // alert(e.data);
      try {
        const json = JSON.parse(e.data);
        if (json.key === "Toggle_Menu_Drawer") layoutCtx.ToggleDrawer();
      } catch {}
    });
  }, []);

  useEffect(() => {
    if (userctx) {
      GetMenu();
    }
  }, [userctx]);

  return (
    <div>
      <FloatingBackgroundGlobalCss />
      <ContactModal contactOpen={contactOpen} setContactOpen={setContactOpen} />
      <BillingModal open={billingOpen} setOpen={setBillingOpen} />
      <CheckRevisionComponent init={{ pattern: 2 }} />
      <ClippedDrawer open={props.isDrawerOpen} items={listItems}>
        <Toolbar />
        <Container
          disableGutters={false}
          style={{ maxWidth: "1280px", marginBottom: theme.spacing(10) }}
        >
          <Suspense
            fallback={
              <div style={{ height: "90vh" }} className={styles.centerFlex}>
                <CircularProgress />
              </div>
            }
          >
            <Switch>
              <AuthorizeRoute path="/mypage" component={MyPageRoute} />
              <AuthorizeRoute path="/manage/" component={ManageRoute} />
              <AuthorizeRoute path="/product/" component={ProductRoute} />
              <AuthorizeRoute path="/user/" component={UserRoute} />
              <AuthorizeRoute path="/stock/" component={StockRoute} />
              <AuthorizeRoute path="/inquiry/" component={InquiryRoute} />
              {/* <AuthorizeRoute path="/price/" component={PriceRoute} /> */}
              {/* <AuthorizeRoute path="/admin" component={AdminRoute} /> */}
            </Switch>
          </Suspense>

          {/* <AuthorizeRoute path="/manage/booking" component={Bookings} /> */}

          {/* TODO お知らせ関係はAdminに移動する

          <ClaimAuthorizeRoute
            contextClaims={userctx.claims}
            claim={userctx && userctx.hasClaim(Claims.Information_Read)}
            claimErrorPath="/not-found"
            path="/manage/information/informationList/"
            component={Information}
          />

          <ClaimAuthorizeRoute
            contextClaims={userctx.claims}
            claim={userctx && userctx.hasClaim(Claims.Information_Create)}
            claimErrorPath="/not-found"
            path="/manage/information/informationRegister"
            component={InformationRegister}
          />
 */}
          {/* TODO メール送信関係はAdminに移動する
          <ClaimAuthorizeRoute
            contextClaims={userctx.claims}
            claim={userctx && userctx.hasClaim(Claims.Notices_Read)}
            claimErrorPath="/not-found"
            path="/manage/notices/index"
            component={Notices}
          />
          <ClaimAuthorizeRoute
            contextClaims={userctx.claims}
            claim={userctx && userctx.hasClaim(Claims.Notices_Create)}
            claimErrorPath="/not-found"
            path="/manage/notices/edit/:pattern"
            component={NoticesEdit}
          />

 */}
          {/* </Suspense> */}
        </Container>
      </ClippedDrawer>
    </div>
  );
}

export default withStyles(commonStyle)(MyPageRoute);
