import { alpha } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { TableRow } from "@mui/material";

export const TableBorderedRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: alpha(theme.palette.primary.light, 0.05),
    },
  },
}))(TableRow);
