import { useEffect } from "react";
import { systemConst } from "./Const";

/**
 * ページタイトルを設定します
 * @example - <PageTitle title="予約一覧" />
 * @param {*} props
 * @param {string} props.title - 指定するページタイトル
 */
export function PageTitle(props) {
  const { title } = props;
  useEffect(() => {
    document.title = title + " - " + systemConst.name;
  }, []);

  return null;
}
