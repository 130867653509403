/**
 * ASP.NET Coreの FileResultで返されたレスポンスをダウンロードする
 * @param {*} response
 */
export default function DownloadBlob(response, filename) {
  //サーバーで設定されたファイル名を取得する
  //attachment; filename=_____20210615135153.csv; filename*=UTF-8''受注一覧_20210615135153.csv
  const url = window.URL.createObjectURL(new Blob([response.data]));
  let fileName = filename;
  if (!filename) {
    var contentDisposition = response.headers["content-disposition"];
    fileName = GetFileName(contentDisposition);
  }

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

function GetFileName(contentDisposition) {
  var match = contentDisposition.match(/filename\s*=\s*(.+);/i);
  var filename = match[1];
  return filename;

  // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  // const matches = filenameRegex.exec(contentDisposition);
  // if (matches != null && matches[1]) {
  //   const fileName = matches[1].replace(/['"]/g, '');
  //   return decodeURI(fileName);
  // }
}
