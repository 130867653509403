import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material/";

/**
 * 確認ダイアログを表示します。
 * @module ConfirmDialog
 * @example
    // 更新処理
    const handleSubmit = async () => {・・・}
    const dialogClose = () => {
        setConfirmDialogOpen(false);
    };
    const handleDialogSubmit = useCallback((e, parameter) => {})
    <ConfirmDialog handleSubmit={handleDialogSubmit} open={confirmDialogOpen} handleClose={dialogClose} message="更新します"/>
 * @param {Object} props - Dialogに準じます
 * @param {function} props.handleClose - キャンセル時処理
 * @param {boolean} props.open - ダイアログの開閉
 * @param {function} props.handleSubmit - OK時処理
 * @param {string} props.message - 表示するメッセージ1
 * @param {string} props.message2 - 表示するメッセージ2
 * @param {string} props.title - タイトル
 * @param {string} props.okLabel - OKボタンラベル「確定」
 * @param {string} props.cancelLabel - キャンセルボタンラベル「キャンセル」
 * @param {string} props.disable1 - OKボタンdisable
 * @param {object} props.parameter - Submit時、Close時に渡すパラメータ
 * @param {object} props.icon - タイトル左横のアイコン
 * @param {object} props.isCancel - キャンセル用かどうか（キャンセル用の場合、いいえが右側にきます）
 */
export function ConfirmDialog(props) {
  const {
    open,
    handleClose,
    handleSubmit,
    message,
    message2,
    title,
    okLabel,
    cancelLabel,
    disable1,
    parameter,
    icon,
    isCancel,
    ...other
  } = props;
  return (
    <div>
      <Dialog open={open} onClose={handleClose} {...other}>
        <DialogTitle>
          <div variant="h6" style={{ color: "#006BB8" }}>
            {
              icon && icon
              //  : (isCancel
              // 	? <Cancel style={{ verticalAlign: "middle" }} />
              // 	: <Check style={{ verticalAlign: "middle" }} />)
            }
            <span style={{ verticalAlign: "middle" }}>
              &nbsp;{title ? title : isCancel ? "キャンセル確認" : "確認"}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div id="alert-dialog-description" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            {/* 更新します。 */}
            {message}
            {message2 ? <br /> : ""}
            {message2}
            {/* <br /> */}
            {/* よろしければ、確定を押下してください。 */}
          </div>
        </DialogContent>
        <DialogActions>
          {!isCancel && (
            <Button onClick={(e) => handleClose(e, parameter)} color="primary" autoFocus>
              {cancelLabel ? cancelLabel : "キャンセル"}
            </Button>
          )}
          <Button
            onClick={(e) => handleSubmit(e, parameter)}
            color="primary"
            disabled={disable1}
            variant={!isCancel ? "contained" : ""}
          >
            {okLabel ? okLabel : isCancel ? "はい" : "確定"}
          </Button>
          {isCancel && (
            <Button
              onClick={(e) => handleClose(e, parameter)}
              color="primary"
              autoFocus
              variant="contained"
            >
              {cancelLabel ? cancelLabel : "いいえ"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
