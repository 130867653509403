import { ExitToApp, ShoppingCart, TouchApp } from "@mui/icons-material";
import { ApplicationPaths, Claims } from "components/api-authorization/ApiAuthorizationConstants";
import { UserContext } from "components/api-authorization/UserContext";
import IconTextButton from "components/input/button/IconTextButton";
import { LayoutContext } from "pages/Layout";
import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import authService from "components/api-authorization/AuthorizeService";
import axios from "axios";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import CustomerSelectDrawer from "components/drawer/CustomerSelectDrawer";
import { localStorageKeys, sessionStorageKeys } from "components/common/Const";
import { MessageContext } from "components/context/MessageContext";
import { ApiPutUsersCustomer } from "components/api/UsersApi";
import useReactRouter from "use-react-router";
import { ConfirmDialog } from "components/dialog/ConfirmDialog";

// //クラスコンポーネントでMaterial-uiのスタイルを当てたい場合はこう書く
// export default withStyles(useStyles)(LoginMenu);

//Drawerを開閉するたびにカートカウントを取得するAPIが実行されるため、
//LoginMenuをfunction化
export default function LoginMenu(props) {
  const userctx = useContext(UserContext);
  const layoutctx = useContext(LayoutContext);
  // const theme = useTheme();
  // const classes = useStyles(props, theme);

  const isImuraStaff = userctx && userctx.hasClaim(Claims.Is_ImuraStaff);
  const history = useHistory();

  useEffect(() => {
    layoutctx.ChangeCartCount();
  }, []);

  const handleLogoutClick = () => {
    var fn = async () => {
      await authService.logout(userctx.user.id, () => userctx.setUser(null), history);
    };
    fn();
  };

  function authenticatedView(userName, profilePath, logoutPath) {
    return (
      <Fragment>
        {/* <IconTextButton
          component={Link}
          to={profilePath}
          className={classes.loginLink}
          text=" 管理 "
        >
          <Settings />
        </IconTextButton> */}
        {/* 得意先変更ボタン */}
        {isImuraStaff && <CustomerChoiceButton />}
        {/* <IconTextButton
          component={Link}
          to={`/mypage/`}
          // className={classes.loginLink} //function化したら何故かclassNameが効かなくなった
          style={{ color: "inherit" }}
          text="Myページ"
        >
          <AccountCircle />
        </IconTextButton> */}

        {/* <IconTextButton
          component={Link}
          to={"/mypage/cart"}
          className={classes.loginLink}
          text="買い物かご"
        >
          <Badge color="secondary" badgeContent={4}>
            <ShoppingCart />
          </Badge>
        </IconTextButton> */}

        {userctx &&
          userctx.customerSettings &&
          userctx.customerSettings.isUseWebOrder &&
          ((userctx.hasClaim(Claims.Is_ImuraStaff) && userctx.customerSettings.canOrderByImura) ||
            !userctx.hasClaim(Claims.Is_ImuraStaff)) && (
            <IconTextButton
              component={Link}
              to={"/mypage/cart"}
              // className={classes.loginLink} //function化したら何故かclassNameが効かなくなった
              badgeContent={layoutctx.cartCount}
              color="primary"
              text="買い物かご"
            >
              <ShoppingCart />
            </IconTextButton>
          )}

        {/* <IconButton
          // edge="end"
          // aria-label="account of current user"
          // aria-controls={this.props.menuId}
          // aria-haspopup="true"
          // onClick={this.props.handleProfileMenuOpen}
          component={Link}
          to={profilePath}
          className={classes.loginLink}
        >
          <AccountCircle />
        </IconButton> */}

        {/* ハンバーガーメニューに表示させる */}
        <IconTextButton
          // component={Link}
          // to={logoutPath}
          // className={classes.loginLink} //function化したら何故かclassNameが効かなくなった
          text="ログアウト"
          color="primary"
          onClick={handleLogoutClick}
        >
          <ExitToApp />
        </IconTextButton>
      </Fragment>
    );
  }

  function anonymousView(registerPath, loginPath) {
    return (
      <Fragment>
        <IconTextButton
          component={Link}
          to="/login"
          color="primary"
          // className={classes.loginLink}
          text="ログイン"
        >
          <ExitToApp />
        </IconTextButton>
      </Fragment>
    );
  }

  if (!userctx.user) {
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;
    return anonymousView(registerPath, loginPath);
  } else {
    const profilePath = "/manage/";
    const logoutPath = {};
    return authenticatedView(userctx.user.userName, profilePath, logoutPath);
  }
}

const CustomerChoiceButton = () => {
  const theme = useTheme();
  const isSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
  const userctx = useContext(UserContext);
  const msgctx = useContext(MessageContext);
  const unmountRef = useUnmountRef();
  const { history } = useReactRouter();
  const [open, setOpen] = useSafeState(unmountRef, false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useSafeState(unmountRef, false); // 確認のダイアログ開閉
  const [selectedArr, setSelectedArr] = useSafeState(unmountRef, false);

  const handleDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelected = async (selectedArr) => {
    if (!selectedArr || selectedArr.length < 1) {
      msgctx.enqueue(`選択されていません`, "error");
      return;
    }

    // setChecked(selectedArr);
    var selected = selectedArr[0];
    await ApiPutUsersCustomer(selected.mcsId, selected.mcsBranchOfficeId)
      .then(async (response) => {
        msgctx.enqueue("得意先を変更しました", "success");
        // ユーザー情報の更新
        await userctx.refreshUser().then(() => {
          history.push("/mypage");
        });
      })
      .catch((error) => {
        msgctx.enqueue(`エラーが発生しました。時間をおいて再実行してくださ`, "error");
      });
  };

  return (
    <>
      {open && (
        <CustomerSelectDrawer
          pOpen={open}
          pHandleClose={handleClose}
          pSetter={(arr) => {
            setSelectedArr(arr);
            handleDialogOpen();
          }}
          pStorageName={sessionStorageKeys.CustomersSearchItemInLoginMenu}
          // pSelectedCustomers={checked}
          // pIsSelectChild={pIsSelectChild}
          pMaxCount={1}
          pGetManage={true}
        />
      )}
      <ConfirmDialog
        handleSubmit={() => {
          handleSelected(selectedArr);
          handleDialogClose();
        }}
        open={confirmDialogOpen}
        handleClose={handleDialogClose}
        message={"得意先を変更するとカートがクリアされます。変更しますか？"}
        okLabel="変更"
        cancelLabel="キャンセル"
      />
      <IconTextButton
        onClick={handleOpen}
        color="primary"
        style={isSizeXs ? { paddingRight: "2px" } : {}}
        text="得意先変更"
      >
        <TouchApp />
      </IconTextButton>
    </>
  );
};
