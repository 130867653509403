import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import * as React from "react";

/**
 * Notistackの閉じるボタンです
 * @example
 * <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
 * @param {*} param0
 * @returns
 */
export default function NotistackCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton style={{ color: "white" }} onClick={() => closeSnackbar(snackbarKey)} size="large">
      <Close />
    </IconButton>
  );
}
