/**
 * @file パスワードを忘れた方へ画面
 */
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect, useCallback, useContext } from "react";
import { Button, Container, Grid, Typography, TextField, Toolbar, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { commonStyle } from "components/styles/CommonStyle";
import ScroollToTopOnMount from "components/behavior/Scroll";
import TitleText from "components/text/TitleText";
import { useForm } from "react-hook-form";
import { ErrMsgKey, GetErrMsg, ErrMsg } from "components/common/ErrorMessageProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { FormDataToJson } from "components/common/FormHelper";
import { Helmet } from "react-helmet";
import LockIcon from "@mui/icons-material/Lock";
import { MessageContext } from "components/context/MessageContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "220px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
export default function ForgotPassword(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const style = commonStyle();
  const theme = useTheme();
  const msgctx = useContext(MessageContext);

  const [btnDisabled, setBtnDisabled] = useSafeState(unmountRef, false);

  //入力チェックのスキーマ定義
  const [schemaData, setSchemaData] = useSafeState(unmountRef, {
    id: yup.string().required(GetErrMsg(ErrMsgKey.required)),
  });
  const schema = yup.object().shape(schemaData);

  //react-hooks-form
  const { register, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(schema), //react-hooks-formのValidationでyupを使用する際はこうする
  });

  
  useEffect(() => {
    //ここに通常更新時のロジックを書く
    return function cleanup() {
      //ここにはUnmount時のロジックを書く
    };
  });

  // submit時処理
  const onSubmit = useCallback(async function onSubmit() {
    setBtnDisabled(true);
    const values = FormDataToJson("forgot-form");
    await axios
      .post("api/forgotpassword", values)
      .then(function (response) {
        // console.log(response);
        // if (response.status == 200) {
        //   props.history.push("/auth/forgotConfirm");
        //   msgctx.enqueue("メールを送信しました", "success");
        // }
        props.history.push("/auth/forgotConfirm");
        return response;
      })
      .catch(function (error) {
        props.history.push("/login");
        msgctx.enqueue("パスワードの変更はできません", "error");
        setBtnDisabled(false);
      });
  });

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <form
        id="forgot-form"
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ScroollToTopOnMount />
        <Toolbar></Toolbar>
        <Container>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item md={12}>
              <Paper style={{ padding: "30px", textAlign: "center" }}>
                <TitleText>パスワードを忘れた方</TitleText>
                <LockIcon style={{ fontSize: 80, color: "#ddd", marginBottom: "20px" }}></LockIcon>
                <Typography>IDを入力してください</Typography>
                <ErrMsg className={style.errMsg} errors={errors} name="summary" />
                {/* TODO EmailInputに変更する */}
                <TextField
                  name="id"
                  fullWidth
                  className={classes.textField}
                  label="ID"
                  variant="outlined"
                  required
                  inputRef={register}
                />
                <ErrMsg className={style.errMsg} errors={errors} name="id" />
                <Button
                  style={{ marginTop: theme.spacing(1), width: "220px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={btnDisabled}
                  onClick={() => {
                    clearErrors();
                  }}
                >
                  送信
                </Button>
                <br />
                <br />
                <Link to="/login">ログイン画面に戻る</Link>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
}
