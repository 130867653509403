import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { LayoutContext } from "pages/Layout";
import { Link } from "react-router-dom";
import InteractiveList from "components/list/InteractiveList";
import { Avatar, Box, Tooltip, useTheme } from "@mui/material";
import { Person } from "@mui/icons-material";
import { UserContext } from "components/api-authorization/UserContext";
import { Claims } from "components/api-authorization/ApiAuthorizationConstants";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawContent: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 2,
    flexDirection: "column",

    "& > div": {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  drawShift: {
    "& > div": {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  content: {
    //[theme.breakpoints.up("md")]: {
    //  marginLeft: drawerWidth,
    //},
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      height: "100%",
      display: "block",
      position: "fixed",
      content: "",
      backgroundColor: "gray",
      opacity: 0.5,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  avatorColor: {
    // color: theme.palette.getContrastText(theme.palette.secondary.dark),
    // backgroundColor: theme.palette.secondary.dark,
  },
}));

export default function ClippedDrawer(props, state) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const theme = useTheme();
  const layoutCtx = useContext(LayoutContext);
  const userctx = useContext(UserContext);

  const { variant, anchor } = props;
  const [userName, setUserName] = useSafeState(unmountRef);
  const [roleName, setRoleName] = useSafeState(unmountRef);
  const [customerName, setCustomerName] = useSafeState(unmountRef);

  useEffect(() => {
    if (userctx && userctx.user) {
      setRoleName(userctx.user.roleName);

      if (userctx.user.contactPersonName) setUserName(userctx.user.contactPersonName);
      if (!userctx.user.contactPersonName) setUserName("ようこそ");
    }

    if (userctx && userctx.customer)
      setCustomerName(
        // `${userctx.customer.mcsCustomerCd} : ${userctx.customer.mcsName1}${userctx.customer.mcsName2}`
        `${userctx.customer.mcsName1}${userctx.customer.mcsName2}`
      );

    // if (userctx && userctx.customer) {
    //  setCustomerName(userctx.customer.mcsName1);
    // }
  }, [userctx]);

  const LoginName = () => {
    return (
      <>
        {userName && (
          <div onClick={layoutCtx.ToggleMain}>
            <List className={classes.root}>
              <ListItem
                button
                component={
                  userctx &&
                  !userctx.hasClaim(Claims.Is_ImuraStaff) &&
                  userctx.customerSettings &&
                  userctx.customerSettings.isUseRegistrationInformation === false
                    ? ""
                    : Link
                }
                to={"/manage/account/index"}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatorColor}>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <Tooltip
                  title={
                    <>
                      {userName}
                      <br />
                      &nbsp;&nbsp;{roleName}
                      <br />
                      &nbsp;&nbsp;{customerName}
                    </>
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6", noWrap: true }}
                    primary={userName}
                    secondaryTypographyProps={{ noWrap: true }}
                    secondary={
                      <>
                        {roleName}
                        <br />
                        {customerName}
                      </>
                    }
                  />
                </Tooltip>
              </ListItem>
            </List>
            <Divider light variant="middle" />
          </div>
        )}
      </>
    );
  };

  return (
    <Box className={(classes.root, "divParent")}>
      <Box id="drawer-container">
        {/* 画面サイズが小さい時は、ドロワーはボタン押下で横から表示 */}
        <Drawer
          className={clsx(classes.drawer, classes.drawContent, {
            [classes.drawShift]: layoutCtx.isDrawerOpen,
          })}
          variant={variant ? variant : "permanent"}
          anchor={anchor ? anchor : "left"}
          open={layoutCtx.isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          sx={{ display: { xs: "block", lg: "none" } }}
          {...props}
        >
          <div className={classes.drawerHeader}>
            <Toolbar />
          </div>
          <div className={classes.drawerContainer}>
            {LoginName()}
            <InteractiveList items={props.items}></InteractiveList>
            <Divider />
          </div>
        </Drawer>
        {/* 画面サイズが大きい時は、ドロワーは固定で表示 */}
        <Drawer
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: { xs: "none", lg: "block" },
          }}
          className={clsx(classes.drawer)}
          variant={variant ? variant : "permanent"}
          anchor={anchor ? anchor : "left"}
          open={layoutCtx.isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          {...props}
        >
          <div className={classes.drawerHeader}>
            <Toolbar />
          </div>
          <div className={classes.drawerContainer}>
            {LoginName()}
            <InteractiveList items={props.items}></InteractiveList>
            <Divider />
          </div>
        </Drawer>
      </Box>

      <main className={classes.content}>
        <div
          className={clsx({
            [classes.contentShift]: layoutCtx.isDrawerOpen,
          })}
          onClick={layoutCtx.ToggleMain}
          onScroll={layoutCtx.ToggleMain}
        ></div>
        {props.children}
      </main>
    </Box>
  );
}
