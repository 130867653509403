import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect, useContext, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";
import NavBar from "components/NavBar";
import authService from "components/api-authorization/AuthorizeService";
import axios from "axios";
import { UserContext } from "components/api-authorization/UserContext";
import useReactRouter from "use-react-router";
import { MessageContext } from "components/context/MessageContext";
import { ErrMsgKey, GetErrMsg } from "components/common/ErrorMessageProvider";
import { ApiGetCustomerSetting } from "components/api/CustomerSettingApi";
import { ApiGetConfig } from "components/api/AzureStorageApi";
import { GetCustomerBranch } from "components/api/CustomerApi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  mainContainer: {
    flex: "1 0 auto",
  },
  footer: {
    textAlign: "right",
    flexShrink: "0",
    marginTop: "auto",
    paddingRight: "20px",
    maxHeight: "25px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  footerDivider: {
    margin: "20px 0px 0px 0px",
  },
  firstContent: {
    marginTop: theme.spacing(5),
  },
}));

export const LayoutContext = React.createContext();

export function Layout(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const msg = useContext(MessageContext);
  const [isDrawerOpen, setIsDrawerOpen] = useSafeState(unmountRef, false);
  const [user, setUser] = useSafeState(unmountRef, null);
  const [storageConfig, setStorageConfig] = useSafeState(unmountRef, null);
  const [claims, setClaims] = useSafeState(unmountRef, null);

  const [customer, setCustomer] = useSafeState(unmountRef, null); //ログイン中のユーザーの顧客情報
  const [customerSettings, setCustomerSettings] = useSafeState(unmountRef, null); //ログイン中のユーザーの得意先設定情報(McdJsonValues)
  const [mceCustomeProductContactMailAddress, setMceCustomeProductContactMailAddress] =
    useSafeState(unmountRef, null); //ログイン中のユーザーの得意先設定情報(MceCustomeProductContactMailAddress)

  let isSuccess = false;
  const [cartCount, setCartCount] = useSafeState(unmountRef, 0);

  //TODO UserContextはトークン情報を入れずに、ユーザーの情報と権限の情報を持つようにする
  //AuthorizeServiceにsubscribeして変更があった場合、サーバーから取り直す

  const { history } = useReactRouter();

  async function GetCartCount() {
    const token = await authService.getAccessToken();
    if (!token) return;

    await axios
      .get("api/Cart?requireAllCount=true", {
        // + queryString.toString(), {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        // console.log(JSON.stringify(response));
        if (response.status === 200) {
          isSuccess = true;
        }
        return response;
      })
      .then(function (json) {
        // console.log(JSON.stringify(json));
        if (!isSuccess) {
          //alert("err");
          console.log("not success");
          //setErrorMessage(json.errorMessage);
        } else {
          // console.log("success");
          //alert("success");
          //alert(json.data);
          // setCartCount(json.data);
          const num = parseInt(json.data);
          if (!Number.isNaN(num)) setCartCount(num);
        }
      })
      .catch(function (error) {
        //alert("cacth");
        console.log("getItem fetch error", error);
      });
  }
  const handleDrawerMenuClick = function () {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleMainClick = function () {
    if (isDrawerOpen) setIsDrawerOpen(!isDrawerOpen);
  };
  const handleCartCount = function () {
    GetCartCount();
    //alert("setCount");
  };

  async function GetUser() {
    //ユーザー情報取得
    if (await authService.isAuthenticated()) {
      const token = await authService.getAccessToken();
      await axios
        .get("api/Account/", {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) setUser(response.data);
        })
        .catch((error) => {
          //TODO エラー処理はどうする？
        });
    }
  }

  async function GetClaims() {
    if (await authService.isAuthenticated()) {
      await authService.getBelongClaims().then((result) => {
        if (result) {
          setClaims(result["claims"]);
        }
      });
    }
  }

  useEffect(() => {
    // authService.subscribe(async () => {
    //   await GetUser();
    //   // 権限取得
    //   await GetClaims();
    //   await ApiGetConfig().then((result) => {
    //     setStorageConfig(result.data);
    //   });
    // });
    GetUser();
    return () => {
      //Unmount時 テスト
      // alert("Unmount");
    };
    //setIsNoneHeader(false);
  }, []);

  const GetCustomer = useCallback(async () => {
    if (user) {
      await GetCustomerBranch(user.customerId, user.customerBranchOfficeId)
        .then(function (response) {
          if (response && response.length > 0) setCustomer(response[0]);
        })
        .catch(function (error) {
          msg.enqueue("得意先が設定されていません", "error");
        });
      //TODO API実装
      // ApiGetCustomer(user.customerId, user.customerBranchOfficeId)
      //   .then(function(response){
      //     if (response && response.length > 0) setCustomer(response[0]);
      //   })
      //   .catch(function (error) {
      //     msg.enqueue("データが取得できませんでした", "error");
      //   });
    }
  });

  async function GetCustomerSetting() {
    if (await authService.isAuthenticated()) {
      if (user) {
        await ApiGetCustomerSetting(user.customerId, user.customerBranchOfficeId)
          .then(function (response) {
            setCustomerSettings(response[0] ? JSON.parse(response[0].mceJsonValues) : {});
            setMceCustomeProductContactMailAddress(
              response[0] && response[0].mceCustomeProductContactMailAddress
            );
          })
          .catch(function (error) {
            //TODO エラー処理はどうする？
            // msgctx.enqueue("得意先設定が取得できませんでした", "error");
          });
      }
    }
  }
  const layoutCtx = useContext(LayoutContext);

  useEffect(() => {
    GetCustomer();
    GetCartCount();

    const asyncF = async () => {
      if (await authService.isAuthenticated()) {
        await GetCustomerSetting();
        await GetClaims();
        await ApiGetConfig().then((result) => {
          setStorageConfig(result.data);
        });
      }
    };
    asyncF();
  }, [user]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {/*ユーザー情報を全コンポーネントに伝播するプロバイダ*/}
        <UserContext.Provider
          value={{
            user: user,
            customer: customer,
            customerSettings: customerSettings,
            mceCustomeProductContactMailAddress: mceCustomeProductContactMailAddress,
            // refreshCustomerSettings: GetCustomerSetting,
            refreshUser: GetUser,
            // refreshCustomer: GetCustomer,
            setUser: setUser,
            claims: claims,
            setClaims: setClaims,
            hasClaim: (claimName) => {
              if (claims) {
                if (claims[claimName] === "true") {
                  return true;
                }
                if (claims["Has_All_Claims"] === "true") {
                  return true;
                }
              }
              return false;
            },
            storageConfig: storageConfig,
            setStorageConfig: setStorageConfig,
          }}
        >
          <LayoutContext.Provider
            value={{
              ToggleMain: handleMainClick,
              ToggleDrawer: handleDrawerMenuClick,
              ChangeCartCount: handleCartCount,
              isDrawerOpen: isDrawerOpen,
              cartCount: cartCount,
            }}
          >
            <NavBar
              onDrawerMenuClick={handleDrawerMenuClick}
              user={user}
              // isMypage={isMypage}
            />
            <Container className={classes.mainContainer} maxWidth={false} disableGutters={true}>
              {props.children}
            </Container>
            <hr id="footer-divider" className={classes.footerDivider} />
            {/* <footer id="footer" className={classes.footer}>
              © 2021 - Imura Envelope CO.,INC.
            </footer> */}
          </LayoutContext.Provider>
        </UserContext.Provider>
      </div>
    </div>
  );
}
