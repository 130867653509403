import React, { useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Slider,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import { commonStyle } from "components/styles/CommonStyle";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ReloadButton from "components/input/button/ReloadButton";

const useStyles = makeStyles((theme) => ({
  pagenation: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& > .MuiPagination-ul": {
      flexWrap: "unset",
    },
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  table_pagenation: {
    "& > .MuiToolbar-root": {
      // flexWrap: "wrap",
    },
    "& > .MuiToolbar-root > p": {
      display: "none",
    },
  },
  noSpin: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));
export function PagenationActions(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const theme = useTheme();
  const styles = commonStyle();
  const isSizeXs = useMediaQuery(theme.breakpoints.down("md"));

  const { count, page, rowsPerPage, onPageChange } = props;
  const [sliderVal, setSliderVal] = useSafeState(unmountRef, page ? page + 1 : 1);

  const max = Math.ceil(count / rowsPerPage);

  useEffect(() => {
    setSliderVal(page + 1);
  }, [page]);
  const handleChange = (event, value) => {
    onPageChange(event, value - 1);
  };
  function valuetext(value) {
    return `${value}°C`;
  }
  function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
      <Tooltip enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }
  return (
    // <div>
    //   <Pagination
    //     size={isSizeXs ? "small" : "medium"}
    //     className={classes.pagenation}
    //     count={Math.ceil(count / rowsPerPage)}
    //     color="primary"
    //     variant="outlined"
    //     shape="rounded"
    //     page={page + 1}
    //     siblingCount={1}
    //     onChange={handleChange}
    //   />
    // </div>
    <>
      {max > 1 && (
        <div
          style={
            isSizeXs
              ? {
                  width: "100%",
                  minWidth: "220px",
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(3),
                }
              : {
                  width: "100%",
                  minWidth: "250px",
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(3),
                }
          }
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconButton
                size="small"
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  var val = sliderVal - 1;
                  if (val <= 0) val = 1;
                  setSliderVal(val);
                  handleChange(null, val);
                }}
              >
                <ArrowBackIos fontSize="small" className={styles.reFontSize} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Slider
                aria-label="pageNo"
                defaultValue={page + 1}
                value={sliderVal}
                getAriaValueText={valuetext}
                components={{
                  ValueLabel: ValueLabelComponent,
                }}
                valueLabelDisplay="auto"
                // size="small"
                min={1}
                max={max}
                onChange={(e, v) => {
                  if (v <= 0) v = 1;
                  setSliderVal(v);
                }}
                onChangeCommitted={(e, v) => {
                  if (v <= 0) v = 1;
                  handleChange(e, v);
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  var val = sliderVal + 1;
                  var limit = Math.ceil(count / rowsPerPage);
                  if (val > limit) val = limit;
                  setSliderVal(val);
                  handleChange(null, val);
                }}
              >
                <ArrowForwardIos fontSize="small" className={styles.reFontSize} />
              </IconButton>
            </Grid>
            <Grid item>
              <Input
                value={sliderVal}
                size="small"
                onChange={(e) => {
                  var val = e.target.value;
                  if (val <= 0) val = 1;
                  var limit = Math.ceil(count / rowsPerPage);
                  if (val > limit) val = limit;
                  setSliderVal(val);
                }}
                onBlur={(e) => {
                  var val = e.target.value;
                  if (val <= 0) val = 1;
                  var limit = Math.ceil(count / rowsPerPage);
                  if (val > limit) val = limit;
                  setSliderVal(val);
                  handleChange(e, val);
                }}
                inputProps={
                  isSizeXs
                    ? {
                        min: 1,
                        max: Math.ceil(count / rowsPerPage),
                        type: "number",
                        "aria-labelledby": "input-slider",
                        style: { textAlign: "right", width: "2rem", fontSize: "85%" },
                      }
                    : {
                        min: 1,
                        max: Math.ceil(count / rowsPerPage),
                        type: "number",
                        "aria-labelledby": "input-slider",
                        style: { textAlign: "right", width: "3rem" },
                      }
                }
                className={classes.noSpin}
                endAdornment={
                  <InputAdornment position="end">
                    <span className={styles.reFontSize}>ページ</span>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

// Propsのデフォルト値
PagenationTable.defaultProps = {
  isFirstSearch: true,
};

/**
 * 【旧バージョン】テーブルにpagenationを付与します。
 * 件数は、x-total-countより取得します。
 * 実際の使用例は「ContactList」を参照
 * @module PagenationTable
 * @example
    const GetContacts = useCallback(async (skip, take) => {検索処理};
    const [refreshTable, setRefreshTable] = useSafeState(unmountRef, false);
    const clickSearch = () =>{
        setRefreshTable(!refreshTable);
    }

    <PagenationTable rowsPerPageOptions={[5,10,20, 50, 100]} rowsPerPageDefault={5} countDefault={0} pageDefault={0} searchFunction={GetContacts} refreshTable={refreshTable} >
 * @param {Object} props - 基本的にTablePaginationに準じます
 * @param {int[]?} props.rowsPerPageOptions - 表示件数を配列で定義（[5,10,20, 50, 100]）
 * @param {int?} props.rowsPerPageDefault - 表示件数の初期値（5）
 * @param {int?} props.countDefault - データ件数（0）表示
 * @param {int?} props.pageDefault - 表示ページの初期値（0）
 * @param {function(skip, take)} props.searchFunction - ページ変更時、件数変更時に実行されるfunction。引数にスキップ件数と取得件数を引き渡す。responseを返すことで、表示件数を動的に変更する
 * @param {bool?} props.requireHeader - テーブル上側に表示するか(true)
 * @param {bool?} props.requireFooter - テーブル下側に表示するか(true)
 * @param {bool} props.refreshTable - 検索等で、データが変わるときに呼び出しもとで変更する。
 * @param {bool} props.isFirstSearch - 初期検索するかどうか。(true)
 * @param {bool} props.isLoading - 読み込み中かどうか
 * @param {bool} props.isRequiredReloadButton - リロードボタンが必要かどうか
 * @param {*} props.setRefreshTable - refreshTableの値変更
 */
export default function PagenationTable(props) {
  const unmountRef = useUnmountRef();
  const styles = commonStyle();

  const {
    rowsPerPageOptions,
    rowsPerPageDefault,
    countDefault,
    pageDefault,
    searchFunction,
    requireHeader,
    requireFooter,
    refreshTable,
    isFirstSearch,
    isLoading,
    isRequiredReloadButton,
    setRefreshTable,
    ...other
  } = props;
  const classes = useStyles();

  const labelRowsPerPage = "表示件数";
  const [rowsPerPage, setRowsPerPage] = useSafeState(
    unmountRef,
    rowsPerPageDefault ? rowsPerPageDefault : 5
  );
  const [page, setPage] = useSafeState(unmountRef, pageDefault ? pageDefault : 0);
  const [count, setCount] = useSafeState(unmountRef, countDefault ? countDefault : 0);
  const [dispHeader] = useSafeState(unmountRef, requireHeader === undefined ? true : requireHeader);
  const [dispFooter] = useSafeState(unmountRef, requireFooter === undefined ? true : requireFooter);
  const [firstSearched, setFirstSearched] = useSafeState(unmountRef, false);

  // 関数
  const labelDisplayedRows = useCallback((data) => {
    // return `${data.from} - ${data.to === -1 ? data.count : data.to} of ${
    //   data.count
    // }`;
    return `${data.count} 件中 ${data.from} - ${
      data.to === -1 ? data.count : data.to
    } 件目を表示中`;
    // return `${page + 1} / ${Math.ceil(count / rowsPerPage)}`;
  });
  // ページ変更
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  });
  // ページ表示件数変更
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  });

  // 検索処理
  const Search = useCallback(async () => {
    var response = await searchFunction(page * rowsPerPage, rowsPerPage);
    // あればレスポンスからカウントを取り出す。
    if (response) {
      if (response.headers["x-total-count"]) setCount(Number(response.headers["x-total-count"]));
    }
  });

  useEffect(() => {
    setFirstSearched(true);

    const cleanup = () => {};
    return cleanup;
  }, []);

  useEffect(() => {
    if (isFirstSearch && !firstSearched) Search();
  }, [firstSearched]);

  useEffect(() => {
    if (firstSearched) Search();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (firstSearched) {
      if (page !== 0) setPage(0);
      else Search();
    }
  }, [refreshTable]);

  function pagination(style) {
    return (
      <TablePagination
        className={style}
        style={{ verticalAlign: "middle", display: "inline-block" }}
        rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={labelRowsPerPage}
        labelDisplayedRows={(data) => labelDisplayedRows(data)}
        {...other}
      />
    );
  }

  const tablePagenation = () => {
    return (
      <>
        {/* sm以下はこっち*/}
        <Box className={styles.visibleSm}>
          {isRequiredReloadButton && (
            <ReloadButton
              refreshTable={refreshTable}
              isLoading={isLoading}
              setPageRefreshTable={(isRefresh) => setRefreshTable(isRefresh)}
            />
          )}
          {pagination(classes.table_pagenation)}
        </Box>
        {/* md以上はこっち */}
        <Box className={styles.hiddenMdDown} style={{ textAlign: "right" }}>
          {isRequiredReloadButton && (
            <ReloadButton
              refreshTable={refreshTable}
              isLoading={isLoading}
              setPageRefreshTable={(isRefresh) => setRefreshTable(isRefresh)}
            />
          )}
          {pagination()}
        </Box>
      </>
    );
  };

  return (
    <>
      {dispHeader && tablePagenation()}
      {props.children}
      {dispFooter && tablePagenation()}
    </>
  );
}
