import axios from "axios";

/**
 * ログインします。
 * @param {*} json - ログイン情報
 */
export async function ApiPostLogin(json) {
  let result;
  await axios
    .post(`api/login`, json)
    .then(function (response) {
      if (response.status === 200) result = response;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * ログインします。
 * @param {*} json - ログイン情報
 */
export async function ApiPostReflesh(token) {
  let result;

  var json = JSON.stringify({ token });

  await axios
    .post(`api/login/reflesh`, json, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then(function (response) {
      if (response.status === 200) result = response;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}
