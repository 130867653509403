import React, { useEffect } from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "components/api-authorization/ApiAuthorizationConstants";
import authService from "components/api-authorization/AuthorizeService";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";

export default function ClaimAuthorizeRoute(props) {
  const unmountRef = useUnmountRef();
  const [authenticated, setAuthenticated] = useSafeState(unmountRef);

  var link = document.createElement("a");
  link.href = props.path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(
    returnUrl
  )}`;

  useEffect(() => {
    var f = async () => {
      setAuthenticated(await authService.isAuthenticated());
    };

    f();
  }, []);

  useEffect(() => {
    var f = async () => {
      setAuthenticated(await authService.isAuthenticated());
    };

    f();
  });

  return (
    <Route
      {...props}
      render={(props) => {
        if (this.props.contextClaims && !this.props.claim) {
          return <Redirect to={this.props.claimErrorPath} />;
        } else if (this.props.contextClaims && authenticated) {
          return <Component {...props} />;
        } else {
          return <></>;
        }
      }}
    />
  );
}

// export default class ClaimAuthorizeRoute extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       ready: false,
//       authenticated: false,
//     };
//   }

//   componentDidMount() {
//     this._subscription = authService.subscribe(() =>
//       this.authenticationChanged()
//     );
//     this.populateAuthenticationState();
//   }

//   componentWillUnmount() {
//     authService.unsubscribe(this._subscription);
//   }

//   render() {
//     const { ready, authenticated } = this.state;
//     var link = document.createElement("a");
//     link.href = this.props.path;
//     const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
//     const redirectUrl = `${ApplicationPaths.Login}?${
//       QueryParameterNames.ReturnUrl
//     }=${encodeURI(returnUrl)}`;
//     if (!ready) {
//       return <div></div>;
//     } else {
//       const { component: Component, ...rest } = this.props; //この行がEdgeではエラーになって何も表示されない
//       return (
//         <Route
//           {...rest}
//           render={(props) => {
//             if (this.props.contextClaims && !this.props.claim) {
//               return <Redirect to={this.props.claimErrorPath} />;
//             } else if (this.props.contextClaims && authenticated) {
//               return <Component {...props} />;
//             } else {
//               return <Redirect to={redirectUrl} />;
//             }
//             // if (authenticated) {
//             //     return <Component {...props} />
//             // } else {
//             //     return <Redirect to={redirectUrl} />
//             // }
//           }}
//         />
//       );
//       // return <Route {...this.props}
//       //     render={(props) => {
//       //         if (authenticated) {
//       //             return <Component {...props} />
//       //         } else {
//       //             return <Redirect to={redirectUrl} />
//       //         }
//       //     }} />
//     }
//   }

//   async populateAuthenticationState() {
//     const authenticated = await authService.isAuthenticated();
//     this.setState({ ready: true, authenticated });
//   }

//   async authenticationChanged() {
//     this.setState({ ready: false, authenticated: false });
//     await this.populateAuthenticationState();
//   }
// }
