import React from "react";
import { Container, Typography, Toolbar } from "@mui/material";
import ScroollToTopOnMount from "components/behavior/Scroll";
import TitleText from "components/text/TitleText";

export default function ForgotConfirm(props) {
  // const layoutCtx = useContext(LayoutContext);
  // layoutCtx.setIsMypage(false);
  return (
    <div>
      <ScroollToTopOnMount />
      <Toolbar></Toolbar>
      <Container>
        <TitleText>メールを送信しました</TitleText>
        <Typography>ご登録頂いているメールアドレスに、メールを送信しました</Typography>
        <Typography>ただちにメールをご確認頂くようお願いいたします。</Typography>
      </Container>
    </div>
  );
}
