import React, { useContext, useEffect } from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import useReactRouter from "use-react-router";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "components/api-authorization/ApiAuthorizationConstants";
import authService from "components/api-authorization/AuthorizeService";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import { UserContext } from "./UserContext";

export default function AuthorizeRoute(props) {
  const { component, ...others } = props;

  return <Route render={(props) => <AuthGuarder component={component} {...props} />} />;
  // const unmountRef = useUnmountRef();
  // const [authenticated, setAuthenticated] = useSafeState(unmountRef);
  // const { history } = useReactRouter();

  // var link = document.createElement("a");
  // link.href = props.path;
  // const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  // const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(
  //   returnUrl
  // )}`;

  // useEffect(() => {
  //   var f = async () => {
  //     setAuthenticated(await authService.isAuthenticated());
  //   };

  //   f();
  // }, []);

  // useEffect(() => {
  //   var f = async () => {
  //     setAuthenticated(await authService.isAuthenticated());
  //   };

  //   f();
  // });

  // // const unAuthenticatedFunc = () => {
  // //   history.push("/login");
  // //   return <></>;
  // // };

  // return (
  //   <Route
  //     {...props}
  //     render={(props) => {
  //       if (authenticated) {
  //         return <Component {...props} />;
  //       } else {
  //         return <></>;
  //       }
  //     }}
  //   />
  // );
}

// 未ログインの場合はログイン画面に戻らせる共通処理
const AuthGuarder = (props) => {
  const userctx = useContext(UserContext);
  const { history } = useReactRouter();
  const { component: Component, ...others } = props;
  useEffect(() => {
    const asyncFunc = async () => {
      var isAuthenticated = await authService.isAuthenticated();

      if (isAuthenticated) return;

      history && history.push("/login");
      userctx && userctx.setUser(null);
    };
    asyncFunc();
  }, []);

  return <Component {...others}></Component>;
};

// //TODO UserContext使うように変えた方が良いかも
// //subscribeのChangedをどうするかが課題だが
// export default class AuthorizeRoute extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       ready: false,
//       authenticated: false,
//     };
//   }

//   componentDidMount() {
//     this._subscription = authService.subscribe(() =>
//       this.authenticationChanged()
//     );
//     this.populateAuthenticationState();
//   }

//   componentWillUnmount() {
//     authService.unsubscribe(this._subscription);
//   }

//   render() {
//     const { ready, authenticated } = this.state;
//     var link = document.createElement("a");
//     link.href = this.props.path;
//     const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
//     const redirectUrl = `${ApplicationPaths.Login}?${
//       QueryParameterNames.ReturnUrl
//     }=${encodeURI(returnUrl)}`;
//     if (!ready) {
//       return <div></div>;
//     } else {
//       const { component: Component, ...rest } = this.props; //この行がEdgeではエラーになって何も表示されない
//       return (
//         <Route
//           {...rest}
//           render={(props) => {
//             if (authenticated) {
//               return <Component {...props} />;
//             } else {
//               return <Redirect to={redirectUrl} />;
//             }
//           }}
//         />
//       );
//       // return <Route {...this.props}
//       //     render={(props) => {
//       //         if (authenticated) {
//       //             return <Component {...props} />
//       //         } else {
//       //             return <Redirect to={redirectUrl} />
//       //         }
//       //     }} />
//     }
//   }

//   async populateAuthenticationState() {
//     const authenticated = await authService.isAuthenticated();
//     this.setState({ ready: true, authenticated });
//   }

//   async authenticationChanged() {
//     this.setState({ ready: false, authenticated: false });
//     await this.populateAuthenticationState();
//   }
// }
