import { useEffect } from "react";

/**
 * 画面ロード時に一番上までスクロールした状態にします
 * @returns
 */
export default function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

/**
 * その要素内のスクロールを一番下までスクロールします
 * @param {*} ref 要素のref
 */
export function ScrollToBottomInRef(ref) {
  //一番下までスクロールして表示する
  const elem = ref.current;
  elem.scrollTo(0, elem.scrollHeight);
}
