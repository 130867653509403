import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useTheme, alpha } from "@mui/material/styles";

export const commonStyle = makeStyles((theme) => ({
  pageTitle: {
    margin: theme.spacing(2),
  },
  cenerTitleInBox: {
    margin: theme.spacing(5),
  },
  loginLabel: {
    padding: "12px",
  },
  loginLink: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  errMsg: {
    color: theme.palette.error.main,
  },
  catchCopy: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  fullWidth: {
    width: "100%",
  },
  toolTip: {
    fontSize: "14px",
  },
  center: {
    textAlign: "center",
  },
  hiddenXs: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenSm: {
    [theme.breakpoints.down("lg")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenMd: {
    [theme.breakpoints.down("xl")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenXsDown: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenSmDown: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenMdDown: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  hiddenLgDown: {
    [theme.breakpoints.down("lg")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },

  visibleXs: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  visibleSm: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  visibleMd: {
    [theme.breakpoints.up("lg")]: {
      display: "none !important",
      visibility: "collapse !important",
    },
  },
  successLight: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  successText: {
    color: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  warningText: {
    color: theme.palette.warning.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  noBorder: {
    border: "none !important",
  },
  sunday: {
    backgroundColor: "#ffe6e6",
  },
  saturday: {
    backgroundColor: "#f0f6ff",
  },
  "@media print": {
    noPrint: {
      display: "none",
      "& *": {
        display: "none",
      },
    },
    printOnly: {
      display: "inherit",
      "& *": {
        display: "inherit",
      },
    },
  },
  noPaddingXs: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  noPaddingX: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  noPaddingY: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  fab: {
    zIndex: "100",
    position: "fixed",
    [theme.breakpoints.up("xs")]: {
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    [theme.breakpoints.down("md")]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  fabL: {
    width: "62px",
    height: "62px",
    fontSize: "0.9rem",
  },
  fab2L: {
    width: "68px",
    height: "68px",
    fontSize: "0.95rem",
  },
  fab3L: {
    width: "76px",
    height: "76px",
    fontSize: "1.0rem",
  },
  // 管理側の新着メッセージがある場合のFab
  manageFab: {
    //暫定
    zIndex: "100",
    position: "fixed",
    [theme.breakpoints.up("lg")]: {
      bottom: theme.spacing(6),
      right: theme.spacing(25),
    },
    [theme.breakpoints.up("xl")]: {
      bottom: theme.spacing(6),
      right: theme.spacing(30),
    },
    [theme.breakpoints.down("lg")]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  /* 超良い感じに改行してくれるcss */
  lineBreak: {
    wordBreak: "break-all",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    lineBreak: "anywhere",
    whiteSpace: "pre-wrap",
    whiteSpace: "breakSpaces",
  },
  /* 画面中央配置 */
  centerTransform: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    "-webkit-transition": "translateY(-50%) translateX(-50%)",
  },
  /* 画面中央配置 flex版 */
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  /* 境界上に配置する 親要素にposition:relativeを指定してください */
  placeOnBorder: {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translateY(-50%) translateX(-1em)",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
  cancelText: {
    margin: theme.spacing(1),
    color: "red",
  },
  cancelBack: {
    background: "rgba(0, 0, 0, 0.2)",
  },

  /* キャンセル時の背景色 */
  cancelBackground: {
    background: "#dcdcdc",
  },

  /* 各項目テーブルのタイトルセル(青) */
  tableCellTitle: {
    width: "50px",
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    textAlign: "center",
  },

  /* 各項目テーブルのタイトルセル(グレー) */
  tableCellTitle2: {
    width: "50px",
    backgroundColor: "rgba(105, 105, 105, 0.1)",
    textAlign: "center",
  },

  delete_button: {
    backgroundColor: theme.palette.error.main,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  /* xsサイズ時のフォントサイズ */
  reFontSize: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "85%",
    },
  },
}));

export const FloatingBackgroundGlobalCss = withStyles({
  "@global": {
    "#root": {
      backgroundColor: "rgba(248,248,248,1)",
    },
  },
})(() => null);
