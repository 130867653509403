import React, { forwardRef, useContext, useCallback, useEffect } from "react";
import {
  Button,
  Grid,
  Modal,
  Typography,
  useTheme,
  useMediaQuery,
  DialogContent,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Mail } from "@mui/icons-material";
import { UserContext } from "components/api-authorization/UserContext";
import IOSSwitch from "components/input/switch/IOSSwitch";
import * as yup from "yup";
import {
  ErrMsg,
  ErrMsgKey,
  GetErrMsg,
  useErrorMessageProvider,
} from "components/common/ErrorMessageProvider";
import { useSafeState, useUnmountRef } from "./UnmountStateHelper";
import { Box, InputLabel, TextField } from "@mui/material/";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonStyle } from "components/styles/CommonStyle";
import { Controller, useForm } from "react-hook-form";
import { ApiPostContactAsync } from "components/api/ContactApi";
import authService from "components/api-authorization/AuthorizeService";
import axios from "axios";
import { MessageContext } from "components/context/MessageContext";
import { validationConst } from "components/common/Const";
import { Font } from "pdfjs-dist/build/pdf.worker";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50vw",
      height: "95vh",
      padding: theme.spacing(2, 4, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 1, 2),
      width: "95vw",
      height: "95vh",
      overflowY: "scroll",
      fontSize: "70%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const ContactCommon = forwardRef((props, ref) => {
  const { title, description, onClose, dbPrefix } = props;
  const unmountRef = useUnmountRef();
  const [userId] = useSafeState(unmountRef, "");
  const msgctx = useContext(MessageContext);
  const classes = useStyles();
  const style = commonStyle();
  const theme = useTheme();
  const isSizeXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useSafeState(unmountRef, null);
  const [msg, setMsg] = useSafeState(unmountRef, null);
  const [btnDisabled, setBtnDisabled] = useSafeState(unmountRef, false); //送信ボタンDisabled

  // テキストの入力文字数をカウント
  const [noteHelperText, setNoteHelperText] = useSafeState(unmountRef, "");
  const handleCommentChange = useCallback((e) => {
    setNoteHelperText(`(現在 ${e.target.value.length} 文字 )`);
  });
  //入力チェックのスキーマ定義
  const [schemaData] = useSafeState(unmountRef, {
    orderNumber: yup.string().required(GetErrMsg(ErrMsgKey.required)),
    productId: yup.string().required(GetErrMsg(ErrMsgKey.required)),
    comment: yup
      .string()
      .required(GetErrMsg(ErrMsgKey.required))
      .max(500, "500文字以下としてください。"),
    phonenumber: yup
      .string()
      .test(`${dbPrefix}Tel`, "10文字以上13文字以下でご入力ください", function (value) {
        //入力が無い場合はエラーを返さない
        if (value === "") {
          return true;
        } else if (value !== "") {
          //ハイフンが含まれている場合;
          if (value.includes("-")) {
            // ハイフンを削除
            value = value.replace(/-/g, "");
          }
          // 先頭0を含む下限10文字上限13文字かチェック
          if (validationConst.phone_reg_exp_no_hyphen.test(value)) return true;
          return false;
        }
      }),
  });
  const schema = yup.object().shape(schemaData);
  //react-hooks-form
  const { register, handleSubmit, errors, setError, watch, control } = useForm({
    resolver: yupResolver(schema), //react-hooks-formのValidationでyupを使用する際はこうする
  });

  // ユーザー情報の取得
  async function GetUser() {
    const token = await authService.getAccessToken();
    await axios
      .get("api/Account", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .catch(function (error) {
        setMsg("登録情報の取得に失敗しました。時間をおいて再度実行してください。");
        console.log("getUser error", error);
      });
  }
  useEffect(() => {
    const f = async () => {
      await GetUser();
    };
    f();
  }, []);
  //ラジオボタン切り替え用
  watch("newProjectsInquiry");
  watch("orderSystemInquiry");
  watch("otherInquiry");
  const onSubmit = useCallback(async function onSubmit() {
    var userName = user.userName;
    var Comment = {};

    Comment.confirmedOrderInquiry = watch("confirmedOrderInquiry");
    Comment.orderNumber = watch("orderNumber");
    Comment.quoteInquiry = watch("quoteInquiry");
    Comment.productId = watch("productId");
    Comment.newProjectsInquiry = watch("newProjectsInquiry");
    Comment.orderSystemInquiry = watch("orderSystemInquiry");
    Comment.otherInquiry = watch("otherInquiry");
    Comment.comment = watch("comment");
    Comment.phonenumber = watch("phonenumber");
    //お問い合わせ目的の選択がない場合
    if (
      Comment.confirmedOrderInquiry == null &&
      Comment.quoteInquiry == null &&
      Comment.newProjectsInquiry == null &&
      Comment.orderSystemInquiry == null &&
      Comment.otherInquiry == null
    ) {
      msgctx.enqueue("お問い合わせ目的を選択してください", "error");
    } else if (
      //条件式を追加することで、yupのバリデーションが実行される
      Comment.comment !== "" &&
      Comment.comment.length <= 500 &&
      Comment.orderNumber !== "" &&
      Comment.productId !== ""
    ) {
      if (
        (Comment.phonenumber !== "" &&
          Comment.phonenumber.length >= 10 &&
          Comment.phonenumber.length <= 13) ||
        Comment.phonenumber == ""
      ) {
        setBtnDisabled(true);
        const token = await authService.getAccessToken();
        await ApiPostContactAsync(Comment, userName, token)
          .then((response) => {
            // 送信処理が成功した場合、以下を実行
            alert("送信しました");
            onClose();
          })
          .catch((error) => {
            msgctx.enqueue("送信できませんでした。入力内容をご確認ください。", "error");
            // 送信処理が失敗した場合、以下を実行
            setError("email", { type: "manual", message: "送信に失敗しました" });
          });
      }
    }
    setBtnDisabled(false);
  });

  return (
    <form id="contact-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container className={classes.paper} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid
              xs={12}
              item
              style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}
            >
              <Typography
                variant={isSizeXs ? "h2" : "h3"}
                color="primary"
                style={{ marginBottom: "3.5rem", textAlign: "center" }}
              >
                {title ? title : "お問い合わせ"}
              </Typography>
              <Typography style={{ marginTop: "0.5rem", textAlign: "center" }}>
                {"お問い合わせの目的（選択必須）"}
              </Typography>
              <br />
              <Typography style={{ marginTop: "0.5rem", textAlign: "center" }}>
                <FormControl>
                  <RadioGroup name="ContactList">
                    <FormControlLabel
                      name={"confirmedOrderInquiry"}
                      label={
                        isSizeXs
                          ? "発注確定済みのご注文について"
                          : "発注確定済みのご注文に関するお問い合わせ"
                      }
                      control={<Radio inputRef={register} value={"confirmedOrderInquiry"} />}
                    />
                    {watch("confirmedOrderInquiry") === "confirmedOrderInquiry" && (
                      <FormControl
                        className={style.formControl}
                        style={{ textAlign: "left", marginLeft: "30px" }}
                      >
                        <TextField
                          name="orderNumber"
                          type="text"
                          className={classes.textField}
                          control={control}
                          defaultValue=""
                          variant="outlined"
                          label="発注番号"
                          inputRef={register}
                          sx={{ width: "70%" }}
                          style={{ marginLeft: "5px" }}
                          required={true}
                          value={watch("orderNumber")}
                          placeholder="例）R2024012300001"
                        />
                        <ErrMsg className={style.errMsg} errors={errors} name="orderNumber" />
                      </FormControl>
                    )}
                    <FormControlLabel
                      name={"quoteInquiry"}
                      label={isSizeXs ? "お見積りについて" : "お見積りに関するお問い合わせ"}
                      control={<Radio inputRef={register} value={"quoteInquiry"} />}
                    />

                    {watch("quoteInquiry") === "quoteInquiry" && (
                      <FormControl
                        className={style.formControl}
                        style={{ textAlign: "left", marginLeft: "30px" }}
                      >
                        <InputLabel htmlFor="productId"></InputLabel>

                        <TextField
                          name="productId"
                          control={control}
                          defaultValue=""
                          variant="outlined"
                          label="製品No"
                          inputRef={register}
                          required
                          sx={{ width: "70%" }}
                          style={{ marginLeft: "5px" }}
                          display="inline-block"
                          onChange={handleCommentChange}
                          placeholder="例）0001001A0002など"
                        />

                        <ErrMsg
                          className={style.errMsg}
                          errors={errors}
                          name="productId"
                          style={{ marginLeft: "50px" }}
                        />
                      </FormControl>
                    )}
                    <FormControlLabel
                      name={"newProjectsInquiry"}
                      label={
                        isSizeXs
                          ? "新規案件のご発注について"
                          : "新規案件のご発注に関するお問い合わせ"
                      }
                      control={<Radio inputRef={register} value={"newProjectsInquiry"} />}
                    />

                    <FormControlLabel
                      name={"orderSystemInquiry"}
                      label={
                        isSizeXs ? "WEB発注システムについて" : "WEB発注システムに関するお問い合わせ"
                      }
                      control={<Radio inputRef={register} value={"orderSystemInquiry"} />}
                    />
                    <FormControlLabel
                      name={"otherInquiry"}
                      label="上記以外のお問い合わせ"
                      control={<Radio inputRef={register} value={"otherInquiry"} />}
                    />
                  </RadioGroup>
                </FormControl>
              </Typography>

              <br />
              <Typography xs={12} style={{ textAlign: "center" }}>
                <label style={{ textAlign: "center" }}>お問い合わせ内容（入力必須）</label>
                <br />

                <TextField
                  name="comment"
                  variant="outlined"
                  className={classes.commentGrid}
                  label="お問い合わせ内容"
                  inputRef={register}
                  multiline
                  sx={{ width: "80%" }}
                  required
                  helperText={"最大500文字" + noteHelperText}
                  rows={isSizeXs ? 6 : 5}
                  onChange={handleCommentChange}
                  placeholder="お問い合わせ内容をご記入下さい。"
                />
                <div
                  xs={12}
                  style={
                    isSizeXs ? { textAlign: "center" } : { marginLeft: "60px", textAlign: "left" }
                  }
                >
                  <ErrMsg className={style.errMsg} errors={errors} name="comment" />
                </div>
              </Typography>
              <br />
              <Typography style={{ textAlign: "center" }}>
                <label style={{ marginRight: "100px", margin: "30px" }}>お電話番号（任意）</label>

                <TextField variant="outlined" name="phonenumber" type="tel" inputRef={register} />

                <ErrMsg
                  className={style.errMsg}
                  style={{ textAlign: "start", marginLeft: "110px" }}
                  errors={errors}
                  name="phonenumber"
                />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                spacing: "10rem",
              }}
            >
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={btnDisabled}
                onClick={(e) => {
                  onSubmit();
                }}
                style={{ width: "10rem", margin: "1rem" }}
              >
                送信する
              </Button>
              <Button
                color="inherit"
                variant="contained"
                style={{ width: "10rem", margin: "1rem" }}
                onClick={onClose}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});

export const ContactModal = (props) => {
  const { contactOpen, setContactOpen } = props;
  const classes = useStyles();
  const onClose = () => {
    setContactOpen(false);
  };
  return (
    <Modal open={contactOpen} onClose={onClose}>
      <DialogContent className={classes.modal}>
        <ContactCommon onClose={onClose}></ContactCommon>
      </DialogContent>
    </Modal>
  );
};
