import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { forwardRef, useCallback, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useSafeState, useUnmountRef } from "./UnmountStateHelper";
import KeybordDatePickerJp from "components/input/textbox/KeybordDatePickerJp";
import { GetApp } from "@mui/icons-material";
import Axios from "axios";
import DownloadBlob from "components/common/DownloadBlob";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50vw",
      height: "80vh",
      padding: theme.spacing(2, 4, 2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 1, 2),
      width: "95vw",
      height: "80vh",
      overflowY: "scroll",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const BillingModal = (props) => {
  const unmountRef = useUnmountRef();
  const { open, setOpen } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

  //入力チェックのスキーマ定義
  const [schemaData] = useSafeState(unmountRef, {});
  const schema = yup.object().shape(schemaData);

  //react-hooks-form
  const { register, handleSubmit, errors, setError, clearErrors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    setOpen(false);
  };

  // TODO 取得処理書く
  const handleClickDownloadCsv = async () => {
    // const elem = document.getElementById(formId);
    // const queryString = elem ? new URLSearchParams(new FormData(elem)) : "";
    // const token = await authService.getAccessToken();

    // return await Axios.get("api/request/shipping/csv?" + queryString.toString(), {
    //   headers: !token ? {} : { Authorization: `Bearer ${token}` },
    //   responseType: "blob",
    // })
    //   .then(function (response) {
    //     DownloadBlob(response, "在庫出荷一覧_" + dayjs().format("YYYYMMDDHHmmss") + ".csv");
    //   })
    //   .catch(function (error) {
    //     msgctx.enqueue("エラーが発生しました。恐れ入りますが、再度お試しください", "error");
    //   });

    // 下記はサンプルデータを出すためにべた書きしているので削除する
    var blob = new Blob([""], { type: "text/csv" });
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ご請求情報" + dayjs().format("YYYYMMDDHHmmss") + ".csv";
    link.click();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <DialogContent className={classes.modal}>
        <Grid container className={classes.paper} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid xs={12} item style={{ textAlign: "center", marginTop: "1rem" }}>
                <Typography variant={isSizeXs ? "h6" : "h4"} color="primary">
                  ご請求情報照会
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  CSV抽出したい期間を指定し、CSV出力ボタンを押下してください
                </Typography>
                <div
                  style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  <KeybordDatePickerJp
                    name="showBgnDate"
                    label="From"
                    inputRef={register}
                    // defaultValue={getValues("showBgnDate")}
                    autoOk={true}
                    style={{ width: "130px" }}
                    // onChangeSearch={(name, date) => {
                    //   setValue(name, date);
                    // }}
                    clearable
                    clearLabel="クリア"
                  />
                  <span
                    style={{
                      display: "inline-block",
                      width: "1.5em",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    ~
                  </span>
                  <KeybordDatePickerJp
                    name="showEndDate"
                    label="To"
                    // defaultValue={getValues("showEndDate")}
                    inputRef={register}
                    autoOk={true}
                    style={{ width: "130px" }}
                    // onChangeSearch={(name, date) => {
                    //   setValue(name, date);
                    // }}
                    clearable
                    clearLabel="クリア"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  variant="contained"
                  style={{ width: "10rem", marginTop: "0.5rem" }}
                  onClick={handleClickDownloadCsv}
                >
                  <GetApp /> CSV出力
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  color="inherit"
                  variant="contained"
                  style={{ width: "10rem" }}
                  onClick={onClose}
                >
                  閉じる
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};
