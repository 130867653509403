import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "components/api-authorization/Login";
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from "components/api-authorization/ApiAuthorizationConstants";
import NotFound from "pages/shared/NotFound";

export default class ApiAuthorizationRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
          <Route
            path={ApplicationPaths.LoginFailed}
            render={() => loginAction(LoginActions.LoginFailed)}
          />
          <Route
            path={ApplicationPaths.LoginCallback}
            render={() => loginAction(LoginActions.LoginCallback)}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Fragment>
    );
  }
}

function loginAction(name) {
  return <Login action={name}></Login>;
}
