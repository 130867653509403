import React, { Fragment } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { PageTitle } from "components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
  titleTextItem: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  drawerTextItem: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xl")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  titleText: {
    border: 1,
    borderRadius: 3,
    fontWeight: "600",
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * ページタイトル用の文字列を表示するコンポーネントです。
 * @param {Object} props
 * @param {boolean} props.isNotSetPageTitle - ブラウザのページタイトルにセットするかどうか
 * @param {boolean} props.isNotShowDecoration - 装飾を表示するかどうか
 * @param {boolean} props.rightComponents - 右側に配置するコンポーネント
 * @param {bool} props.rightComponentsXs - rightComponentsのGridをxs={12}にする（ボタンが左寄せになる現象解消の為）
 */
export default function TitleText(props) {
  const classes = useStyles();
  const {
    isNotSetPageTitle,
    isNotShowDecoration,
    rightComponents,
    rightComponentsXs,
    variant,
    isDrawer,
  } = props;
  return (
    <Fragment>
      {isNotSetPageTitle !== true && <PageTitle title={props.children} />}
      {/* <hr /> */}
      <Grid
        container
        className={classes.titleTextContainer}
        direction="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item className={isDrawer ? classes.drawerTextItem : classes.titleTextItem}>
          <Typography
            variant={variant ? variant : "h4"}
            className={clsx(classes.titleText, props.className)}
            color={props.color ? props.color : "primary"}
          >
            {props.children}
          </Typography>
        </Grid>
        <Grid item className={classes.titleTextItem} xs={rightComponentsXs && 12}>
          {rightComponents}
        </Grid>
      </Grid>
      {isNotShowDecoration !== true && <Divider className={classes.divider} />}
    </Fragment>
  );
}
