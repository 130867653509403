import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";

// /**
//  * 顧客の情報を取得します
//  * 注意　branchOfficeId渡しても検索されない。
//  * @param {*} cid - カスタマーID
//  * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
//  */
// export async function GetCustomer(cid, branchOfficeId, token) {
//   let result;
//   let tkn = token;
//   if (!tkn) tkn = await authService.getAccessToken();
//   await axios
//     .get(`api/customers?customerId=${cid}&branchOfficeId=${branchOfficeId}`, {
//       headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
//     })
//     .then(function (response) {
//       if (response.status === 200) result = response.data;
//     })
//     .catch(function (error) {
//       throw error;
//     });
//   return result;
// }

/**
 * 顧客の情報を取得します
 * CustomersController GetCustomerBranch
 * @param {*} cid - カスタマーID
 * @param {*} branchOfficeId - 部署ID
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function GetCustomerBranch(cid, branchOfficeId, token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get(`api/customers/customerBranch?customerId=${cid}&branchOffice=${branchOfficeId}`, {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 得意先の一覧を取得します
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 * CustomersController.cs の GetCustomersList()
 */
export async function GetMyCustomers(token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/customers/customersList?", {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 担当顧客の一覧を取得します
 * @param {*} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function GetMyClients(token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/customers/selectlist?", {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 顧客の支店の一覧を取得します
 * @param {int} customerId - 支店一覧を取得する顧客のID
 * @param {String} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function GetClientBranches(customerId, token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .get("api/customers/selectlist?", {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
      params: { customerId: customerId },
    })
    .then(function (response) {
      if (response.status === 200) result = response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}

/**
 * 顧客の一覧を取得します
 * @param {string} values - 引き渡すクエリパラメータ文字列
 * @param {String} token - アクセストークン 既に取得済みの場合、指定してください。未指定の場合、取得します
 */
export async function ApiSearchCustomers(values, token) {
  let result;
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  await axios
    .post(`api/customers/select`, values, {
      headers: !tkn ? {} : { Authorization: `Bearer ${tkn}` },
    })
    .then(function (response) {
      if (response.status === 200) result = response;
    })
    .catch(function (error) {
      throw error;
    });
  return result;
}
