import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect, useContext } from "react";
import { Grid, Typography, Toolbar, Fab, Hidden, useMediaQuery } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ScrollToTopOnMount from "components/behavior/Scroll";
import { LayoutContext } from "pages/Layout";
import { PageTitle } from "components/common/PageTitle";
import "css/topKeyFrames.css";
import clsx from "clsx";
import { Launch, Login } from "@mui/icons-material";
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);
const useStyles = makeStyles((theme) => ({
  smxsonly: {
    [theme.breakpoints.up("md")]: { display: "none" },
  },
  pic1: {
    right: "5%",
    top: "17%",
    position: "absolute",
    animation: "wobbling_x 3.5s ease-in-out infinite alternate",
    [theme.breakpoints.down("md")]: { right: "5%" },
    "& > img": {
      animation: "wobbling_y 4.5s ease-in-out infinite alternate",
      [theme.breakpoints.down("lg")]: { width: "10vw" },
      [theme.breakpoints.down("sm")]: { width: "12vw" },
    },
  },
  pic2: {
    right: "7%",
    top: "60%",
    position: "absolute",
    animation: "wobbling_x 4.5s ease-in infinite alternate",
    [theme.breakpoints.down("md")]: { right: "3%" },
    "& > img": {
      animation: "wobbling_y 3.5s ease-in infinite alternate",
      [theme.breakpoints.down("lg")]: { width: "10vw" },
      [theme.breakpoints.down("sm")]: { width: "20vw" },
    },
  },
  pic3: {
    left: "10%",
    top: "25%",
    position: "absolute",
    animation: "wobbling_x 3.5s ease-in-out infinite alternate",
    [theme.breakpoints.down("md")]: { left: "2%" },
    "& > img": {
      animation: "wobbling_y 4s ease-in-out infinite alternate",
      [theme.breakpoints.down("lg")]: { width: "10vw" },
      [theme.breakpoints.down("sm")]: { width: "15vw" },
    },
  },
  pic4: {
    left: "5%",
    top: "55%",
    position: "absolute",
    animation: "wobbling_x 4s ease-in infinite alternate",
    [theme.breakpoints.down("md")]: { left: "2%" },
    "& > img": {
      animation: "wobbling_y 3s ease-in infinite alternate",
      [theme.breakpoints.down("lg")]: { width: "10vw" },
      [theme.breakpoints.down("sm")]: { width: "12vw" },
    },
  },
  square: {
    position: "absolute",
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(255, 255, 255, 0.39)",
    animation:
      "wobbling_bx 4s ease-in-out infinite alternate, wobbling_by 3s ease-in-out infinite alternate",
    "&:nth-child(1)": {
      width: "30px",
      height: "30px",
      top: "15%",
      left: "7%",
      animation:
        "wobbling_bx 3s ease-in-out infinite alternate, wobbling_by 4s ease-in-out infinite alternate",
    },
    "&:nth-child(2)": {
      width: "30px",
      height: "30px",
      top: "50%",
      right: "5%",
      animation:
        "wobbling_bx 3s ease-in-out infinite alternate, wobbling_by 5s ease-in-out infinite alternate",
    },
    "&:nth-child(3)": {
      width: "30px",
      height: "30px",
      top: "42%",
      left: "10%",
      animation:
        "wobbling_bx 4s ease-in-out infinite alternate, wobbling_by 4s ease-in-out infinite alternate",
    },
    "&:nth-child(4)": {
      width: "50px",
      height: "50px",
      top: "75%",
      left: "15%",
      animation:
        "wobbling_bx 4s ease-in-out infinite alternate, wobbling_by 3s ease-in-out infinite alternate",
    },
    "&:nth-child(5)": {
      width: "20px",
      height: "20px",
      top: "10%",
      [theme.breakpoints.down("md")]: { top: "15%", left: "15%" },
      left: "20%",
      animation:
        "wobbling_bx 3s ease-in-out infinite alternate, wobbling_by 5s ease-in-out infinite alternate",
    },
    "&:nth-child(6)": {
      width: "40px",
      height: "40px",
      top: "12%",
      right: "20%",
      animation:
        "wobbling_bx 3.5s ease-in-out infinite alternate, wobbling_by 3.5s ease-in-out infinite alternate",
    },
    "&:nth-child(7)": {
      width: "60px",
      height: "60px",
      top: "40%",
      right: "15%",
      animation:
        "wobbling_bx 3.7s ease-in-out infinite alternate, wobbling_by 4.7s ease-in-out infinite alternate",
    },
    "&:nth-child(8)": {
      width: "50px",
      height: "50px",
      top: "80%",
      right: "4%",
      animation:
        "wobbling_bx 4s ease-in-out infinite alternate, wobbling_by 3s ease-in-out infinite alternate",
    },
  },

  top: {
    display: "inline-block",
    paddingTop: "5%",
    color: "white",
    [theme.breakpoints.down("md")]: { paddingTop: "10%" },
  },
  toptext1: {
    padding: "0 1px 30px 1px",
    fontWeight: "bold",
    fontSize: "230%",
    fontFamily: "UD デジタル 教科書体 NP-R, Hiragino Maru Gothic ProN W4, Yu Gothic, Meiryo UI",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "5%",
      fontSize: "130%",
    },
  },
  headerLogo: {
    width: "30%",
    [theme.breakpoints.down("md")]: { width: "40%" },
    "@media screen and (max-width: 500px) ": { width: "55%" },
  },
  toptext2: {
    padding: "30px 10px",
    fontSize: "120%",
    fontFamily: "UD デジタル 教科書体 NP-R, Hiragino Maru Gothic ProN W4, Yu Gothic, Meiryo UI",
    [theme.breakpoints.down("md")]: { fontSize: "110%" },
  },
  mainImgDiv: {
    position: "relative",
    "@media screen and (max-width: 500px) ": { height: "200px" },
    "@media screen and (min-width: 500px) ": { height: "250px" },
    "@media screen and (min-width: 800px) ": { height: "300px" },
    "@media screen and (min-width: 1200px) ": { height: "380px" },
  },
  mainImg: {
    position: "absolute",
    width: "100%",
    top: "10px",
  },
  mockup: {
    width: "600px",
    "@media screen and (max-width: 900px) ": { width: "85%" },
    "@media screen and (min-width: 900px) ": { width: "700px" },
    "@media screen and (min-width: 1200px) ": { width: "800px" },
  },
  main: {
    margin: "200px 5% 0 5%",
    textAlign: "center",
    "@media screen and (max-width: 400px) ": { marginTop: "5%" },
    "@media screen and (min-width: 400px) ": { marginTop: "15%" },
    "@media screen and (min-width: 600px) ": { marginTop: "22%" },
    "@media screen and (min-width: 700px) ": { marginTop: "200px" },
  },
  topbtn: {
    cursor: "default",
    fontSize: "large",
    fontWeight: "bold",
    width: "100%",
    padding: "2% 0",
    display: "block",
    textDecoration: "none",
    transition: ".4s",
    [theme.breakpoints.down("sm")]: { fontSize: "15px" },
  },
  btnMail: {
    color: "white",
    border: "solid 2px " + theme.palette.primary.main,
    background: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "none",
      background: "white",
      color: alpha(theme.palette.primary.light, 0.6),
      border: "solid 2px " + alpha(theme.palette.primary.light, 0.6),
    },
  },
  btnLogin: {
    color: theme.palette.primary.main,
    border: "solid 2px " + theme.palette.primary.main,
    background: "white",
    "&:hover": {
      textDecoration: "none",
      background: alpha(theme.palette.primary.light, 0.6),
      border: "solid 2px " + alpha(theme.palette.primary.light, 0.1),
      color: "white",
    },
  },
  btnProduct: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    background: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    "&:hover": {
      textDecoration: "none",
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  aboutEmidel: {
    margin: "15% 0px 5% 0px",
    [theme.breakpoints.down("sm")]: { marginTop: "20%" },
    [theme.breakpoints.up("lg")]: { marginTop: "10%" },
  },
  aboutEmidelContent: {
    "@media screen and (min-width: 1500px) ": {
      padding: "0px 7%",
    },
  },
  maintext1: {
    fontSize: "200%",
    lineHeight: "50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "140%",
      lineHeight: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "120%",
      lineHeight: "35px",
    },
  },
  mainIcon: {
    padding: "5%",
    "@media screen and (min-width: 1500px) ": {
      padding: "2%",
    },
  },
  icon: {
    fontSize: "64px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: { fontSize: "50px" },
  },
  iconTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "large",
    margin: "3%",
    [theme.breakpoints.down("md")]: { fontSize: "100%" },
  },
  aboutContent: {
    [theme.breakpoints.down("md")]: { fontSize: "90%" },
  },
  moreEmidelText: {
    margin: "7% 0px 5% 0px",
    fontSize: "180%",
    lineHeight: "50px",
    [theme.breakpoints.up("lg")]: { marginTop: "6%" },
    [theme.breakpoints.down("md")]: {
      marginTop: "15%",
      fontSize: "130%",
      lineHeight: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "120%",
      lineHeight: "35px",
    },
  },
  moreEmidel: {
    border: "1px solid " + alpha(theme.palette.primary.light, 0.3),
    margin: "2%",
    padding: "30px 0",
    height: "200px",
    lineHeight: "20px",
    [theme.breakpoints.down("md")]: { padding: "40px 0" },
  },
  moreItem: {
    marginTop: "5px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    [theme.breakpoints.up("sm")]: { display: "flex" },
    display: "block",
    padding: "5%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginBottom: "-21px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 2%",
    },
  },
  footDescription: {
    margin: "8% 0",
    width: "450px",
    [theme.breakpoints.down("md")]: { width: "300px" },
    [theme.breakpoints.down("sm")]: { width: "90%" },
  },
  footmenu: {
    [theme.breakpoints.up("sm")]: { marginLeft: "auto" },
    [theme.breakpoints.down("sm")]: { margin: "8% 0" },
  },
  footmenuA: {
    cursor: "default",
    color: "white",
    transition: ".4s",
    textDecoration: "none",
    "&:hover": {
      transition: ".4s",
      color: "black",
    },
  },
  xsonlySpan: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
      width: "5em",
    },
  },
  lineupImgBox: {
    position: "relative",
    top: "-30px",
    zIndex: "800",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      top: "50px",
      marginTop: "-30px",
      marginBottom: theme.spacing(2),
    },
  },
  swipImg: {
    width: "105%",
    [theme.breakpoints.down("md")]: { width: "70%" },
    [theme.breakpoints.down("sm")]: { width: "90%" },
  },
  lineupDescription: {
    textAlign: "left",
    position: "relative",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    minHeight: "300px",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      paddingLeft: 0,
      backgroundColor: "none",
    },
  },
  lineupContent: {
    lineHeight: "35px",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    [theme.breakpoints.down("md")]: {
      lineHeight: "30px",
      fontSize: "90%",
      backgroundColor: "white",
      width: "90%",
      padding: theme.spacing(4),
      paddingTop: theme.spacing(6),
      marginLeft: "auto",
    },
  },
  fab: {
    zIndex: "999",
    position: "fixed",
    opacity: "0.9",
    background: "#E6A800",
    color: "white",
    width: "140px",
    height: "140px",
    transition: ".4s",
    boxShadow: "0 0 0 10px",
    bottom: theme.spacing(6),
    right: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "100px",
      boxShadow: "0 0 0 3px",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(3),
      right: theme.spacing(1),
      width: "65px",
      height: "65px",
    },
    "&:hover": {
      background: "#f8b500",
      color: "white",
      opacity: "0.5",
    },
  },
  fabIcon: {
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
  },
  fabName: {
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function Home(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const theme = useTheme();
  const [imgLoaded, setImgLoaded] = useSafeState(unmountRef, false);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setImgLoaded(true);
  });
  useEffect(() => {}, []);

  if (!imgLoaded) {
    return (
      <div>
        <ScrollToTopOnMount></ScrollToTopOnMount>
        <Toolbar></Toolbar>
        <span>Loading...</span>
      </div>
    );
  } else {
    return (
      <div>
        <PageTitle title="ホーム" />
        <ScrollToTopOnMount></ScrollToTopOnMount>
        <Toolbar></Toolbar>
        <Grid container style={{ background: "linear-gradient(#4889C0 40%, #6DA0CC, white)" }}>
          <Grid style={{ position: "relative", width: "100%", textAlign: "center" }}>
            <div className={classes.square}></div>
            {!smDown && (
              <>
                <div className={classes.square}></div>
                <div className={classes.square}></div>
              </>
            )}
            <div className={classes.square}></div>
            <div className={classes.square}></div>
            {!smDown && (
              <>
                <div className={classes.square}></div>
                <div className={classes.square}></div>
              </>
            )}
            <div className={classes.square}></div>
            <div className={classes.pic1}>
              <img src="./img/huutochan.svg" width="50%" alt="封筒ちゃん１" />
            </div>
            <div className={classes.pic2}>
              <img src="./img/huutochan2.svg" width="48%" alt="封筒ちゃん２" />
            </div>
            <div className={classes.pic3}>
              <img src="./img/huutochan3.svg" width="45%" alt="封筒ちゃん３" />
            </div>
            <div className={classes.pic4}>
              <img src="./img/huutochan4.svg" width="50%" alt="封筒ちゃん４" />
            </div>

            <Grid className={classes.top}>
              <Grid>
                <Typography className={classes.toptext1}>いつもの封筒の発注を、Webで</Typography>
              </Grid>
              <Grid>
                <img
                  src="./img/logo_white.png"
                  className={classes.headerLogo}
                  alt="WEB発注システム powered by IMURA"
                />
              </Grid>
              <Grid>
                <Typography className={classes.toptext2}>
                  選ぶ、送信　するだけで
                  <br />「<span style={{ color: "#FFD050" }}>貴社のための封筒</span>
                  」を簡単発注
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.mainImgDiv}>
              <Grid className={classes.mainImg}>
                <img src="./img/top.png" className={classes.mockup} alt="PC画像" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.main}>
          <Grid style={{ margin: "3% 0%" }}>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                className={clsx(classes.btnLogin, classes.topbtn)}
                // style={{ marginLeft: "5%" }}
                onClick={() => {
                  props.history.push({
                    pathname: `/login`,
                  });
                }}
              >
                <span style={{ fontSize: "x-large" }}>
                  <Login />
                </span>
                <br />
                ログイン
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <div
            className={classes.footer}
            style={{
              // 真ん中の紹介文等が復活したらこのstyleは消す
              marginTop: theme.spacing(8),
            }}
          >
            <div>
              <div>
                <img src="./img/imura_logo.png" width="300px" alt="IMURAロゴ" />
              </div>
              <div className={classes.footDescription}>
                株式会社イムラはスタンダード市場に上場、創業100年を迎えた、封筒業界のリーディングカンパニーです。
              </div>
              <div>
                <span style={{ display: "inline-block", width: "4em" }}>東京本社</span>
                ：〒105-0023
                <br />
                <span style={{ display: "inline-block", width: "5em" }}></span>
                東京都港区芝浦一丁目2-3 &nbsp;
                <br className={classes.smxsonly} />
                <span className={classes.xsonlySpan}></span>シーバンスS館10階
                <br />
                <span style={{ display: "inline-block", width: "4em" }}>大阪本社</span>
                ：〒542-0076
                <br />
                <span style={{ display: "inline-block", width: "5em" }}></span>
                大阪市中央区難波五丁目1-60 &nbsp;
                <br className={classes.smxsonly} />
                <span className={classes.xsonlySpan}></span>なんばスカイオ18階
              </div>
            </div>
            <div className={classes.footmenu}>
              <Typography>
                <a href="https://www.imura.co.jp/" className={classes.footmenuA}>
                  イムラについて&nbsp;
                  <Launch style={{ verticalAlign: "top" }} />
                </a>
              </Typography>
              <br />
              <Typography>
                <a href="https://service.imura.co.jp/" className={classes.footmenuA}>
                  Rmitp®（リミップ）&nbsp;
                  <Launch style={{ verticalAlign: "top" }} />
                </a>
              </Typography>
              <br />
              {/* <Typography>
                <a href="https://emidel-tokyop.com/" className={classes.footmenuA}>
                  診療予約システム えみでる&nbsp;
                  <Launch style={{ verticalAlign: "top" }} />
                </a>
              </Typography>
              <br /> */}
              <Typography
                className={classes.footmenuA}
                onClick={() => {
                  props.history.push({
                    pathname: `/login`,
                  });
                }}
              >
                ログイン&nbsp;
                <Login style={{ verticalAlign: "top" }} />
              </Typography>
              <br />
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}
