import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  TableCell,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { ApiSearchCustomers } from "components/api/CustomerApi";
import {
  CustomerBranchOfficeName,
  CustomerName,
  sessionStorageKeys,
} from "components/common/Const";
import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import { MessageContext } from "components/context/MessageContext";
import CustomerSearchButton from "components/input/button/CustomerSearchButton";
import EditScreenButtons from "components/input/button/EditScreenButtons";
import TablePagenation from "components/pagenation/TablePagenation";
import { TableBorderedRow } from "components/table/TableBorderedRow";
import { TableHeaderCell } from "components/table/TableHeaderCell";
import React, { useContext, useEffect } from "react";
import { FormatCustomerId } from "components/common/FormatCustomerId";
import TitleText from "components/text/TitleText";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  xsFont: {
    [theme.breakpoints.down("md")]: {
      fontSize: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "90%",
    },
  },
  xsCheckCell: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  xsCell: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1, 1, 0),
    },
  },
  editScreenButtons: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },
  drawerContentBox: {
    [theme.breakpoints.down("sm")]: {
      width: "82vw",
    },
    padding: theme.spacing(1, 2),
    width: "80vw",
    height: "100%",
  },
}));

/**
 * 得意先選択と検索ボタン
 * @module CustomerSelectDrawer
 * @example
    pStorageNameから、再表示時にセッションから表示したい場合は、下記のようにopenでくくる必要がある。
    {open && (
      <CustomerSelectDrawer
        pOpen={open}
        pHandleClose={handleClose}
        pSetter={pSetter}
        pStorageName={
          sessionStorageKeys.CustomerSettingSelectDrawerSearchItem
        }
      />v
    )}
 * @param {bool} pOpen - ドロワーの表示制御
 * @param {function} pHandleClose - ドロワーのクローズ処理
 * @param {function} pSetter - 取得した得意先情報を格納するファンクション　引数としてMstCustomerのリストを引き渡します。
 * @param {string} pStorageName - 入力すると、初期表示時、セッションストレージより検索値を取得する
 * @param {Object} pSelectedCustomers - 初期選択される得意先　MstCustomerのリスト
 * @param {int} pMaxCount - 最大選択数
 * @param {bool} pIsSelectChild - 子のみ抽出
 * @param {bool} pIsSelectParentAndChild - 親子のみ抽出
 * @param {bool} pGetManage - 得意先選択ですべての得意先を取るため
 */
const CustomerSelectDrawer = (props) => {
  const unmountRef = useUnmountRef();
  // メッセージ関連
  const msgctx = useContext(MessageContext);
  const classes = useStyles();

  const LimitCount = 500;

  const {
    pOpen,
    pHandleClose,
    pSetter,
    pStorageName,
    pSelectedCustomers,
    pMaxCount,
    pIsSelectChild,
    pIsSelectParentAndChild,
    pGetManage,
  } = props;
  const formId = "search-customer-form";

  const [checked, setChecked] = useSafeState(
    unmountRef,
    pSelectedCustomers ? pSelectedCustomers : []
  );
  const [customers, setCustomers] = useSafeState(unmountRef, []);
  const [refreshTable, setRefreshTable] = useSafeState(unmountRef, false); // PagenationTableにデータ再取得させるためのフラグ
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useSafeState(unmountRef, false);
  const [isFirstSearch, setIsFirstSearch] = useSafeState(unmountRef, true);

  const theme = useTheme();
  const isSizeLg = useMediaQuery(theme.breakpoints.down("xl"));
  const isSizeMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isSizeSm = useMediaQuery(theme.breakpoints.down("md"));
  const isSizeXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggle = (targetCustomer) => {
    const current = checked.find(
      (x) =>
        x.mcsId === targetCustomer.mcsId && x.mcsBranchOfficeId === targetCustomer.mcsBranchOfficeId
    );
    let newChecked = [...checked];
    if (current) {
      newChecked = checked.filter(
        (x) =>
          !(
            x.mcsId === targetCustomer.mcsId &&
            x.mcsBranchOfficeId === targetCustomer.mcsBranchOfficeId
          )
      );
    } else {
      // 複数選択しない場合は、１件以上はエラーとする
      if (pMaxCount && newChecked.length >= pMaxCount) {
        msgctx.enqueue(`${pMaxCount}件まで選択できます`, "error");
        return;
      }
      if (newChecked.length >= LimitCount) {
        msgctx.enqueue(`${LimitCount}件まで選択できます`, "error");
        return;
      }
      newChecked.push(targetCustomer);
    }

    setChecked(newChecked);
  };

  // 得意先の取得
  const GetCustomers = async (skip, take) => {
    var sendValues = {};
    if (isFirstSearch && pStorageName) {
      const preSearchItems = pStorageName && JSON.parse(sessionStorage.getItem(pStorageName));
      if (preSearchItems) {
        for (let key in preSearchItems) {
          sendValues[key] = preSearchItems[key];
        }
      }
      setIsFirstSearch(false);
    } else {
      const elem = document.getElementById(formId);
      if (elem) {
        for (var idx = 0; idx < elem.elements.length; idx++)
          sendValues[elem.elements[idx].name] = elem.elements[idx].value;
      }
    }
    sendValues["offset"] = skip;
    sendValues["take"] = take;
    sendValues["requireAllCount"] = true;
    sendValues["isSelectChild"] = pIsSelectChild ? pIsSelectChild : false;
    sendValues["isSelectParentAndChild"] = pIsSelectParentAndChild
      ? pIsSelectParentAndChild
      : false;
    if (pGetManage !== undefined) sendValues["GetManage"] = pGetManage;
    var checkedArr = [];
    if (checked)
      checked.map((mcs) => {
        checkedArr.push(mcs.mcsCustomerCd);
      });
    if (checkedArr.length > 0) sendValues["checked"] = JSON.stringify(checkedArr);

    // const queryString = new URLSearchParams(sendValues);

    // 検索モーダルを閉じておく
    setIsSearchDrawerOpen(false);

    return await ApiSearchCustomers(sendValues)
      .then(function (response) {
        setCustomers(response.data);
        return response;
      })
      .catch(function () {
        msgctx.enqueue("得意先が取得できませんでした", "error");
        return undefined;
      });
  };

  // ユーザー検索処理
  const SearchCustomers = () => {
    setRefreshTable(!refreshTable);
    return "";
  };

  useEffect(() => {
    if (pSelectedCustomers === undefined || pSelectedCustomers === null) {
      setChecked([]);
    } else {
      setChecked(pSelectedCustomers);
    }
  }, [pSelectedCustomers]);

  const tableHeaderDef = () => {
    return (
      <>
        <TableHeaderCell style={{ width: "8rem" }} className={classes.xsCell}>
          {!pMaxCount && (
            <Typography noWrap>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        var newChecked = checked.concat(
                          customers.filter(
                            (x) =>
                              !checked.find(
                                (y) =>
                                  x.mcsId === y.mcsId && x.mcsBranchOfficeId === y.mcsBranchOfficeId
                              )
                          )
                        );
                        if (newChecked.length <= LimitCount)
                          setChecked(
                            checked.concat(
                              customers.filter(
                                (x) =>
                                  !checked.find(
                                    (y) =>
                                      x.mcsId === y.mcsId &&
                                      x.mcsBranchOfficeId === y.mcsBranchOfficeId
                                  )
                              )
                            )
                          );
                        else msgctx.enqueue(`${LimitCount}件まで選択できます`, "error");
                      } else {
                        setChecked(
                          checked.filter(
                            (x) =>
                              !customers.find(
                                (y) =>
                                  x.mcsId === y.mcsId && x.mcsBranchOfficeId === y.mcsBranchOfficeId
                              )
                          )
                        );
                      }
                    }}
                    checked={
                      checked.filter((x) =>
                        customers.find(
                          (y) => x.mcsId === y.mcsId && x.mcsBranchOfficeId === y.mcsBranchOfficeId
                        )
                      ).length === customers.length && checked.length !== 0
                    }
                    indeterminate={
                      checked.filter((x) =>
                        customers.find(
                          (y) => x.mcsId === y.mcsId && x.mcsBranchOfficeId === y.mcsBranchOfficeId
                        )
                      ) !== customers.length && checked.length !== 0
                    }
                  />
                }
                label="このページを全て選択"
              />
            </Typography>
          )}
        </TableHeaderCell>
        <TableHeaderCell className={classes.xsCell}>
          <Typography noWrap className={classes.xsFont}>
            得意先CD
          </Typography>
        </TableHeaderCell>
        <TableHeaderCell className={classes.xsCell}>
          <Typography noWrap className={classes.xsFont}>
            {CustomerName}
          </Typography>
        </TableHeaderCell>
        {!isSizeSm && (
          <TableHeaderCell className={classes.xsCell}>
            <Typography noWrap className={classes.xsFont}>
              {CustomerBranchOfficeName}
            </Typography>
          </TableHeaderCell>
        )}
      </>
    );
  };

  const tableBodyDef = () => {
    return (
      <>
        {customers.map((x) => {
          var rowChecked = Boolean(
            checked.find((y) => y.mcsId === x.mcsId && y.mcsBranchOfficeId === x.mcsBranchOfficeId)
          );
          var customerId = `${FormatCustomerId(x.mcsId, x.mcsBranchOfficeId)}`;
          return (
            <TableBorderedRow
              key={`customers_${x.mcsId}_${x.mcsBranchOfficeId}`}
              onClick={() => handleToggle(x)}
            >
              <TableCell style={{ textAlign: "center" }} className={classes.xsCheckCell}>
                <Checkbox
                  checked={rowChecked}
                  disabled={pMaxCount && checked && checked.length >= pMaxCount && !rowChecked}
                  size={isSizeMd ? "small" : "medium"}
                />
              </TableCell>
              <TableCell className={classes.xsCell}>
                <Typography noWrap className={classes.xsFont}>
                  {customerId}
                </Typography>
              </TableCell>
              <TableCell className={classes.xsCell}>
                <Typography noWrap className={classes.xsFont}>
                  {x.mcsName1}
                  {isSizeSm && (
                    <>
                      {" "}
                      <br />
                      {x.mcsName2}{" "}
                    </>
                  )}
                </Typography>
              </TableCell>
              {!isSizeSm && (
                <TableCell className={classes.xsCell}>
                  <Typography noWrap className={classes.xsFont}>
                    {x.mcsName2}
                  </Typography>
                </TableCell>
              )}
            </TableBorderedRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={pOpen}
        onClose={pHandleClose}
        PaperProps={{
          style: isSearchDrawerOpen && {
            right: "260px",
            transition: "all 225ms",
          },
        }}
        style={{ zIndex: 1200 }}
      >
        <Toolbar />
        <Box
          display="flex"
          flexDirection="column"
          className={classes.drawerContentBox}
          justifyContent="center"
        >
          <Grid
            container
            justifyContent="center"
            style={{
              position: "relative",
            }}
          >
            <Grid
              item
              xs={12}
              sm={11}
              lg={10}
              xl={8}
              style={{
                position: "relative",
              }}
            >
              <TitleText
                rightComponents={
                  <Grid container spacing={1}>
                    <Grid item>
                      <CustomerSearchButton
                        pStorageName={pStorageName}
                        pIsSearchDrawerOpen={isSearchDrawerOpen}
                        pSetIsSearchDrawerOpen={setIsSearchDrawerOpen}
                        pFormId={formId}
                        pSearchCustomers={SearchCustomers}
                        pUseClearBtn={true}
                      />
                    </Grid>
                  </Grid>
                }
                isNotSetPageTitle={true}
                variant={isSizeLg ? "h5" : "h4"}
                isDrawer={true}
              >
                得意先選択
              </TitleText>
            </Grid>
          </Grid>
          <Box flexGrow={1} style={{ position: "relative" }}>
            <Grid
              container
              justifyContent="center"
              style={{
                position: "relative",
                height: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                sm={11}
                lg={10}
                xl={8}
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    position: "absolute",
                    maxHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TablePagenation
                    storageName={sessionStorageKeys.CustomerSettingSelectDrawerPageNo}
                    head={tableHeaderDef()}
                    body={tableBodyDef()}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    rowsPerPageDefault={10}
                    countDefault={0}
                    pageDefault={0}
                    isFirstSearch={true}
                    searchFunction={GetCustomers}
                    refreshTable={refreshTable}
                    pagenationStyle={{ overflow: "unset" }}
                    requireFooter={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={11} md={8} className={classes.editScreenButtons}>
                <EditScreenButtons
                  text1={
                    <>
                      <Check />
                      &nbsp;選択
                    </>
                  }
                  type1="button"
                  onClick1={() => {
                    // 複数選択しない場合は、１件以上はエラーとする
                    if (pMaxCount && checked.length > pMaxCount) {
                      msgctx.enqueue(`${pMaxCount}件のみ選択できます`, "error");
                      return;
                    }

                    pSetter(checked);
                    setChecked([]);
                    pHandleClose();
                  }}
                  fullWidth
                  variant="contained"
                  isUse3={true}
                  text3={
                    <>
                      <Close />
                      &nbsp;閉じる
                    </>
                  }
                  type3="button"
                  onClick3={() => {
                    setChecked([]);
                    pHandleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default CustomerSelectDrawer;
