import axios from "axios";
import {
  ApplicationName,
  ApplicationPaths,
} from "components/api-authorization/ApiAuthorizationConstants";
import { localStorageKeys } from "components/common/Const";

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated() {
    const token = await this.getAccessToken();
    return !!token;
  }

  // async getUser() {
  //   if (this._user && this._user.expired) return false;
  //   if (this._user && this._user.profile) {
  //     return this._user.profile;
  //   }

  //   await this.ensureUserManagerInitialized();
  //   const user = await this.userManager.getUser();
  //   if (user && user.expired) return false;
  //   return user && user.profile;
  // }

  async getAccessToken() {
    return localStorage.getItem(localStorageKeys.LoginJWT);
  }

  // async getUserInfo() {
  //   // アクセストークン取得
  //   const token = await this.getAccessToken();
  //   // ユーザー情報取得
  //   let profile;
  //   await axios
  //     .get("connect/userinfo", {
  //       headers: !token ? {} : { Authorization: `Bearer ${token}` },
  //     })
  //     .then(function (response) {
  //       profile = response.data;
  //     })
  //     .catch(function (error) {
  //       console.log("userinfo error", error);
  //     });

  //   return profile;
  // }

  async getBelongClaims() {
    // アクセストークン取得
    const token = await this.getAccessToken();
    // ユーザー情報取得
    let claims;
    await axios
      .get("api/getbelongclaims", {
        headers: !token ? {} : { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        claims = response.data;
      })
      .catch(function (error) {
        console.log("getbelongclaims error", error);
      });

    return claims;
  }

  // // We try to authenticate the user in three different ways:
  // // 1) We try to see if we can authenticate the user silently. This happens
  // //    when the user is already logged in on the IdP and is done using a hidden iframe
  // //    on the client.
  // // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  // //    Pop-Up blocker or the user has disabled PopUps.
  // // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  // //    redirect flow.
  // async signIn(state) {
  //   await this.ensureUserManagerInitialized();
  //   try {
  //     const silentUser = await this.userManager.signinSilent(
  //       this.createArguments()
  //     );
  //     this.updateState(silentUser);
  //     return this.success(state);
  //   } catch (silentError) {
  //     // User might not be authenticated, fallback to popup authentication
  //     try {
  //       if (this._popUpDisabled) {
  //         throw new Error(
  //           "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
  //         );
  //       }

  //       const popUpUser = await this.userManager.signinPopup(
  //         this.createArguments()
  //       );
  //       this.updateState(popUpUser);
  //       return this.success(state);
  //     } catch (popUpError) {
  //       if (popUpError.message === "Popup window closed") {
  //         // The user explicitly cancelled the login action by closing an opened popup.
  //         return this.error("The user closed the window.");
  //       } else if (!this._popUpDisabled) {
  //         console.log("Popup authentication error: ", popUpError);
  //       }

  //       // PopUps might be blocked by the user, fallback to redirect
  //       try {
  //         await this.userManager.signinRedirect(this.createArguments(state));
  //         return this.redirect();
  //       } catch (redirectError) {
  //         console.log("Redirect authentication error: ", redirectError);
  //         return this.error(redirectError);
  //       }
  //     }
  //   }
  // }

  // async completeSignIn(url) {
  //   try {
  //     await this.ensureUserManagerInitialized();
  //     const user = await this.userManager.signinCallback(url);
  //     this.updateState(user);
  //     return this.success(user && user.state);
  //   } catch (error) {
  //     console.log("There was an error signing in: ", error);
  //     return this.error("There was an error signing in.");
  //   }
  // }

  // // We try to sign out the user in two different ways:
  // // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  // //    Pop-Up blocker or the user has disabled PopUps.
  // // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  // //    post logout redirect flow.
  // async signOut(state) {
  //   await this.ensureUserManagerInitialized();
  //   try {
  //     if (this._popUpDisabled) {
  //       throw new Error(
  //         "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
  //       );
  //     }

  //     await this.userManager.signoutPopup(this.createArguments());
  //     this.updateState(undefined);
  //     return this.success(state);
  //   } catch (popupSignOutError) {
  //     console.log("Popup signout error: ", popupSignOutError);
  //     try {
  //       await this.userManager.signoutRedirect(this.createArguments(state));
  //       return this.redirect();
  //     } catch (redirectSignOutError) {
  //       console.log("Redirect signout error: ", redirectSignOutError);
  //       return this.error(redirectSignOutError);
  //     }
  //   }
  // }

  // async completeSignOut(url) {
  //   await this.ensureUserManagerInitialized();
  //   try {
  //     const response = await this.userManager.signoutCallback(url);
  //     this.updateState(null);
  //     return this.success(response && response.data);
  //   } catch (error) {
  //     console.log(`There was an error trying to log out '${error}'.`);
  //     return this.error(error);
  //   }
  // }

  // updateState(user) {
  //   this._user = user;
  //   this._isAuthenticated = !!this._user;
  //   this.notifySubscribers();
  // }

  // subscribe(callback) {
  //   this._callbacks.push({
  //     callback,
  //     subscription: this._nextSubscriptionId++,
  //   });
  //   return this._nextSubscriptionId - 1;
  // }

  // unsubscribe(subscriptionId) {
  //   const subscriptionIndex = this._callbacks
  //     .map((element, index) =>
  //       element.subscription === subscriptionId
  //         ? { found: true, index }
  //         : { found: false }
  //     )
  //     .filter((element) => element.found === true);
  //   if (subscriptionIndex.length !== 1) {
  //     throw new Error(
  //       `Found an invalid number of subscriptions ${subscriptionIndex.length}`
  //     );
  //   }

  //   this._callbacks.splice(subscriptionIndex[0].index, 1);
  // }

  // notifySubscribers() {
  //   for (let i = 0; i < this._callbacks.length; i++) {
  //     const callback = this._callbacks[i].callback;
  //     callback();
  //   }
  // }

  // createArguments(state) {
  //   return { useReplaceToNavigate: true, data: state };
  // }

  // error(message) {
  //   return { status: AuthenticationResultStatus.Fail, message };
  // }

  // success(state) {
  //   return { status: AuthenticationResultStatus.Success, state };
  // }

  // redirect() {
  //   return { status: AuthenticationResultStatus.Redirect };
  // }

  // async ensureUserManagerInitialized() {
  //   if (this.userManager !== undefined) {
  //     return;
  //   }

  //   //ここでASP.NET CoreのOidcConfigurationControllerから値を取得している
  //   let response = await fetch(
  //     ApplicationPaths.ApiAuthorizationClientConfigurationUrl
  //   );
  //   if (!response.ok) {
  //     throw new Error(`Could not load settings for '${ApplicationName}'`);
  //   }

  //   //OIDCクライアントの設定はここで行っているが、
  //   //基本的には上記のCoreのOidcConfigurationControllerから取得した内容である
  //   let settings = await response.json();
  //   settings.automaticSilentRenew = true;
  //   settings.includeIdTokenInSilentRenew = true;
  //   settings.userStore = new WebStorageStateStore({
  //     prefix: ApplicationName,
  //     store: localStorage,
  //     // store: sessionStorage //localStorageではなく、sessionStrageに入れてみるテスト
  //   });

  //   this.userManager = new UserManager(settings);

  //   this.userManager.events.addUserSignedOut(async () => {
  //     await this.userManager.removeUser();
  //     this.updateState(undefined);
  //   });
  // }

  async logout(userid, fn, history) {
    var postObj = {};
    postObj["userId"] = userid;
    postObj["roleId"] = "";
    var token = await authService.getAccessToken();

    console.log("token");
    console.log(token);
    if (userid && token) {
      await axios
        .post("api/logout", postObj, {
          headers: !token
            ? { Accept: "application/json", "Content-Type": "application/json" }
            : {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        })
        .then((response) => {
          console.log(userid + "をログアウトさせました。");
          localStorage.removeItem(localStorageKeys.LoginJWT);
          history && history.push("/login");
          fn();
        })
        .catch((error) => {
          console.log("失敗しました");
          // msgctx.enqueue(
          //   error.response.data + "時間をおいて再度実行してください。",
          //   "error"
          // );
          // isSuccess = false;
          history && history.push("/login");
        });
    } else {
      localStorage.removeItem(localStorageKeys.LoginJWT);
      history && history.push("/login");
      fn();
    }
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

// export const AuthenticationResultStatus = {
//   Redirect: "redirect",
//   Success: "success",
//   Fail: "fail",
// };

// import axios from "axios";
// import { localStorageKeys } from "components/common/Const";

// export const GetAccessToken = async (props) => {
//   return localStorage.getItem(localStorageKeys.LoginJWT);
// };

//   // //
//   // const isAuthenticated = async () => {
//   //   const user = await this.getUser();
//   //   return !!user;
//   // }

//   // const getUser = async () => {
//   //   if (this._user && this._user.expired) return false;
//   //   if (this._user && this._user.profile) {
//   //     return this._user.profile;
//   //   }

//   //   await this.ensureUserManagerInitialized();
//   //   const user = await this.userManager.getUser();
//   //   if (user && user.expired) return false;
//   //   return user && user.profile;
//   // }

//   // const getUserInfo = async () => {
//   //   // アクセストークン取得
//   //   const token = await this.getAccessToken();
//   //   // ユーザー情報取得
//   //   let profile;
//   //   await axios
//   //     .get("connect/userinfo", {
//   //       headers: !token ? {} : { Authorization: `Bearer ${token}` },
//   //     })
//   //     .then(function (response) {
//   //       profile = response.data;
//   //     })
//   //     .catch(function (error) {
//   //       console.log("userinfo error", error);
//   //     });

//   //   return profile;
//   // }
// }
