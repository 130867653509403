import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect } from "react";
import { Badge, Box, TextField, Typography } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme, alpha } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import jaLocale from "date-fns/locale/ja";
import { StaticDatePicker, MobileDatePicker, DesktopDatePicker } from "@mui/lab";
import dayjs from "dayjs";
import PickersDay from "@mui/lab/PickersDay";
import PickersModalDialog from "@mui/lab/internal/pickers/PickersModalDialog";
import { styled } from "@mui/system";

if (jaLocale && jaLocale.options) {
  jaLocale.options.weekStartsOn = 0;
}

const useStyles = makeStyles((theme, props) => ({}));

/**
 * カレンダーの各日付の右上に表示されるバッヂのスタイル
 */
const CalendarBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 8,
    padding: 0,
  },
}));

/**
 * 検索項目用のGridに囲まれた日付を返します。
 * @module KeybordDatePickerJp
 * @example
    <KeybordDatePickerJp className={classes.input_span} name="fromDate" text="抽出開始日" />
 * @param {Object} props - KeyboardDatePickerに準ずる
                            https://material-ui-pickers.dev/api/KeyboardDatePicker
 * @param {string} props.format - 日付表示フォーマット「yyyy/MM/dd」
 * @param {string} props.okLabel - OKボタンラベル「決定」
 * @param {string} props.cancelLabel - キャンセルボタンラベル「キャンセル」
 * @param {string} props.todayText - その日のボタンラベル「今日」
 * @param {string} props.title - ダイアログのタイトルテキスト 規定値は「日付を選択」
 * @param {string} props.toolbarFormat - 画面上部の選択日付表示フォーマット「YYYY年MM月DD日」
 * @param {Date} props.defaultValue - 規定値
 * @param {Boolean} props.isShowNextArrow - 翌日の矢印を表示する
 * @param {Boolean} props.isShowPrevArrow - 前日の矢印を表示する
 * @param {Boolean} props.isShowCalendarOnlyIconClicked - カレンダーアイコンがクリックされた時だけカレンダーを表示する
 * @param {Object} props.holidays - 休日設定 MstCalendar
 * @param {Boolean} props.isDisableHoliday - 休日を選択不可にする
 * @param {Boolean} props.disabled 
 * @param {} props.other - その他
 */
export default function KeybordDatePickerJp(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles(props);
  const theme = useTheme();
  const {
    format,
    todayText,
    okLabel,
    cancelLabel,
    defaultValue,
    setValue,
    name,
    inputRef,
    title,
    toolbarFormat,
    placeholder,
    clearErrors,
    onChangeSearch,
    isShowNextArrow,
    isShowPrevArrow,
    isShowCalendarOnlyIconClicked,
    label,
    holidays,
    isDisableHoliday,
    disabled,
    ...other
  } = props;

  // 値保持用
  const [selectedDate, setSelectedDate] = useSafeState(
    unmountRef,
    defaultValue ? new Date(defaultValue) : null
  );

  // MobileDatePicker用
  const [open, setOpen] = useSafeState(unmountRef, false);

  // ダイアログ式用
  const [tempValue, setTempValue] = useSafeState(unmountRef, null);

  // Error有無
  const [textFieldError, setTextFieldError] = useSafeState(unmountRef, false);

  const handleError = (props) => {
    if (props != null) {
      setTextFieldError(true);
    } else setTextFieldError(false);
  };

  // 値変更時処理
  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (Number.isNaN(new Date(date).getTime())) return;
    onChangeSearch && onChangeSearch(name, date);
    clearErrors && clearErrors(name);
    setValue && setValue(name, date);
    setOpen(false);
  };

  const renderDayComponent = (day, selectedDate, DayComponentProps) => {
    const isHoliday =
      !DayComponentProps.outsideCurrentMonth &&
      // calendarContext && calendarContext.holidays &&
      // calendarContext.holidays.some(
      holidays &&
      holidays.some((x) => new Date(x.mcdNonWorkingYmd).getTime() === day.getTime());
    return (
      <CalendarBadge
        key={day.toString()}
        color="warning"
        badgeContent={isHoliday ? "休" : undefined}
      >
        <PickersDay
          style={
            day.getDay() === 6 || day.getDay() === 0
              ? day.getDay() === 6
                ? { color: "blue" }
                : { color: "red" }
              : undefined
          }
          {...DayComponentProps}
          disabled={isHoliday && isDisableHoliday}
        />
      </CalendarBadge>
    );
  };

  const ToolbarComponent = (
    <div
      style={{
        color: "white",
        background: theme.palette.primary.main,
        padding: theme.spacing(2, 3),
      }}
    >
      <Typography variant="h6" style={{ opacity: "0.5" }}>
        {!selectedDate || Number.isNaN(selectedDate.getDate())
          ? "--年"
          : dayjs(new Date(selectedDate)).format("YYYY年")}
      </Typography>
      <Typography variant="h4">
        {!selectedDate || Number.isNaN(selectedDate.getDate())
          ? "--月--日"
          : dayjs(new Date(selectedDate)).format("M月D日")}
      </Typography>
    </div>
  );

  useEffect(() => {
    // refを引き渡せないmaterialUiのコンポーネントをregisterに設定する
    inputRef && inputRef(name); // custom register Antd input
  }, [inputRef]);

  useEffect(() => {
    const date = defaultValue ? new Date(defaultValue) : null;
    setSelectedDate(date);
    setValue && setValue(name, date);
  }, [defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <Box component="span" style={{ display: "inline-flex" }} className={classes.calendarBox}>
        {!isShowCalendarOnlyIconClicked ? (
          // キーボード入力不可
          <MobileDatePicker
            mask={"____/__/__"}
            onError={(props) => handleError(props)}
            inputRef={inputRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            // inputFormat={format ? format : "____/__/__"}
            value={selectedDate}
            onChange={(date) => handleDateChange(date)}
            KeyboardButtonProps={{
              "aria-label": "日付設定",
            }}
            okText={""}
            cancelText={""}
            todayText={""}
            toolbarFormat={toolbarFormat ? toolbarFormat : "yyyy年MM月dd日"}
            showToolbar={true}
            ToolbarComponent={(props) => <>{ToolbarComponent}</>}
            renderInput={(params) => (
              <TextField
                name={name}
                variant="standard"
                placeholder={placeholder}
                onClick={(e) => setOpen(true)}
                helperText={textFieldError && "無効な日付です"}
                {...params}
              />
            )}
            renderDay={(day, selectedDate, DayComponentProps) => {
              return renderDayComponent(day, selectedDate, DayComponentProps);
            }}
            disabled={disabled}
            {...other}
          />
        ) : (
          // キーボード入力可能
          <>
            <DesktopDatePicker
              mask={"____/__/__"}
              // inputFormat={format ? format : "____/__/__"}
              onError={(props) => handleError(props)}
              label={label}
              value={selectedDate}
              open={false}
              onChange={(newValue) => handleDateChange(newValue)}
              onOpen={() => {
                setTempValue(selectedDate);
                setOpen(true);
              }}
              renderInput={(params) => (
                <TextField
                  error={textFieldError}
                  variant="standard"
                  name={name}
                  helperText={textFieldError && "無効な日付です"}
                  {...params}
                />
              )}
              disabled={disabled}
            />
            <PickersModalDialog
              open={open}
              okText={""}
              cancelText={""}
              todayText={""}
              onDismiss={() => setOpen(false)}
              disabled={disabled}
            >
              <StaticDatePicker
                // inputFormat={format ? format : "____/__/__"}
                mask={"____/__/__"}
                disableCloseOnSelect
                displayStaticWrapperAs="mobile"
                value={tempValue}
                onChange={(date) => handleDateChange(date)}
                renderInput={(params) => <TextField {...params} />}
                showToolbar={true}
                ToolbarComponent={() => <>{ToolbarComponent}</>}
                renderDay={(day, selectedDate, DayComponentProps) => {
                  return renderDayComponent(day, selectedDate, DayComponentProps);
                }}
                disabled={disabled}
              />
            </PickersModalDialog>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
}
