export const ApplicationName = "IEOS";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const LogoutActions = {
  LogoutCallback: "logout-callback",
  Logout: "logout",
  LoggedOut: "logged-out",
};

export const LoginActions = {
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed",
  Profile: "profile",
  Register: "register",
};

const prefix = "/authentication";

export const ApplicationPaths = {
  DefaultLoginRedirectPath: "/",
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  //Login: "/login",
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  // LogOut: `${prefix}/${LogoutActions.Logout}`,
  // LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  // LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  // IdentityRegisterPath: '/Identity/Account/Register',
  IdentityManagePath: "/Identity/Account/Manage",
  IdentityRegisterPath: "/register",
};

export const Claims = {
  // 役割のLv
  Role_Lv: "Role_Lv",
  //ユーザーの登録が可能となる
  User_Create: "User_Create",
  //ユーザーの編集が可能となる
  User_Edit: "User_Edit",
  //ユーザーの削除が可能となる
  User_Delete: "User_Delete",
  //ユーザーの参照が可能となる
  User_Read: "User_Read",
  //ユーザーのExcel取込機能が使用可能となる
  User_Import: "User_Import",
  //他ユーザーのパスワード変更が可能となる
  Change_Password_Others: "Change_Password_Others",
  //ユーザー権限編集
  UserClaims_Edit: "UserClaims_Edit",
  //役割の登録が可能となる
  Role_Create: "Role_Create",
  //役割の削除が可能となる
  Roles_Delete: "Roles_Delete",
  //役割の編集が可能となる
  Roles_Edit: "Roles_Edit",
  //役割の参照が可能となる
  Roles_Read: "Roles_Read",
  //役割権限の編集が可能となる
  RoleClaims_Edit: "RoleClaims_Edit",
  //得意先設定の編集が可能となる
  CustomerSetting_Edit: "CustomerSetting_Edit",
  //お知らせの登録が可能となる
  Information_Create: "Information_Create",
  //お知らせの編集が可能となる
  Information_Edit: "Information_Edit",
  //お知らせの削除が可能となる
  Information_Delete: "Information_Delete",
  //管理者向けを含む全てお知らせの参照が可能となる
  AllInformation_Read: "AllInformation_Read",
  // 承認設定_編集
  ApprovalSetting_Edit: "ApprovalSetting_Edit",
  // 承認設定_削除
  ApprovalSetting_Delete: "ApprovalSetting_Delete",
  // 承認設定_参照
  ApprovalSetting_Read: "ApprovalSetting_Read",
  // グループの編集が可能となる
  Group_Edit: "Group_Edit",
  // グループの削除が可能となる
  Group_Delete: "Group_Delete",
  // グループの参照が可能となる
  Group_Read: "Group_Read",
  // 得意先親子設定の編集が可能となる
  CustomerRelation_Edit: "CustomerRelation_Edit",
  // 得意先親子設定の削除が可能となる
  CustomerRelation_Delete: "CustomerRelation_Delete",
  // 得意先親子設定の参照が可能となる
  CustomerRelation_Read: "CustomerRelation_Read",
  //在庫品一覧の参照が可能となる
  StocksList_Read: "StocksList_Read",
  //在庫品作成依頼の登録が可能となる
  StocksCreateRequest_Create: "StocksCreateRequest_Create",
  //在庫品出荷依頼の登録が可能となる
  StocksShippingRequest_Create: "StocksShippingRequest_Create",
  //在庫品作成履歴の参照が可能となる
  StocksCreateHistory_Read: "StocksCreateHistory_Read",
  //在庫品出荷履歴の参照が可能となる
  StocksShippingHistory_Read: "StocksShippingHistory_Read",
  //発注サイトの設定画面を編集することができる
  Settings_Edit_For_Customer: "Setting_Edit_For_Customer",

  //管理サイトにアクセスできる
  Is_ImuraStaff: "Is_ImuraStaff",
  //担当者設定が可能となる
  Operator_Edit: "Operator_Edit",
  //管理側で受注一覧等の数量と価格変更が可能となる
  Qty_Price_Edit: "Qty_Price_Edit",
  //管理側で確定後の出荷日が変更が可能となる
  ConfirmedDate_Edit: "ConfirmedDate_Edit",
  //メッセージの削除が可能
  Message_Delete: "Message_Delete",
  //メッセージ送信のON/OFF切り替えが可能
  Switchi_Send_Message: "Switchi_Send_Message",
  //連携された注文のキャンセルが可能
  ExportedOrder_Cancel: "ExportedOrder_Cancel",
  //全ての権限を持つ
  Has_All_Claims: "Has_All_Claims",

  // IEOSの今後の変更で削除予定　→　Lvに置き換える
  //Administoratorの管理が可能となる
  Manage_Administorator: "Manage_Administorator",
  //イムラ封筒管理者の管理が可能となる
  Manage_ImuraEnvelope: "Manage_ImuraEnvelope",

  // DBにない　けど使ってるから置いておく
  Edit_Account_Info: "Edit_Account_Info",
};
