import React, { useContext } from "react";
import { Container } from "@mui/material";
import { LayoutContext } from "pages/Layout";

export default function NotFound() {
  return (
    <Container maxWidth="xl">
      <div style={{ marginTop: "70px" }}>
        お探しのページは見つかりませんでした。
        <br />- 404 NotFound
      </div>
    </Container>
  );
}
