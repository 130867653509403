import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const IOSSwitchStyle = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: theme.spacing(1),
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

/**
 * IOS風のスイッチを返します。
 * @module IOSSwitch
 * @example
 *
    <IOSSwitch
        disabled
        checked={claimList[item]['checked']}
        name={claimList[item]['title']}
        label={claimList[item]['title']}
    />
 * @param {Object} props - Switchに準じます
 * @param {string} label - 表示用ラベル
 * @param {string} labelStyle - ラベルのスタイル
 */
export default function IOSSwitch(props) {
  const { label, labelStyle, ...other } = props;
  // const classes = useStyles();
  // const theme = useTheme();

  return (
    <>
      <FormControlLabel style={labelStyle} control={<IOSSwitchStyle {...other} />} label={label} />
    </>
  );
}
