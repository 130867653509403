import axios from "axios";
import useReactRouter from "use-react-router";
import authService from "components/api-authorization/AuthorizeService";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import { localStorageKeys } from "components/common/Const";
import { ApiPostReflesh } from "components/api/LoginApi";

export default function UnauthorizeRedirector(props) {
  const userctx = useContext(UserContext);
  const { history } = useReactRouter();
  if (axios.interceptors.response && axios.interceptors.response.handlers.length < 1)
    axios.interceptors.response.use(undefined, async function (error) {
      // const redirectUrl = props.to != null ? props.to : "/login";
      if (error.response.status === 401) {
        console.log("401を検出");
        const originalRequest = error.config;
        var jwt = localStorage.getItem(localStorageKeys.LoginJWT);
        if (!jwt) {
          console.log("トークン無し");
          history && history.push("/login");
          userctx && userctx.setUser(null);
          return;
        }
        var isRefleshSuccess = false;

        var promise = await ApiPostReflesh(jwt)
          .then(async (response) => {
            if (response && response.data && response.data.token) {
              localStorage.setItem(localStorageKeys.LoginJWT, response.data.token);
              isRefleshSuccess = true;
              return response.data.token;
            } else {
              //処理なし
              console.log("トークン切れ1");
              history && history.push("/login");
              userctx && userctx.setUser(null);
              return Promise.reject(error);
            }
          })
          .catch((e) => {
            //処理なし
            console.log("トークン切れ2");
            history && history.push("/login");
            userctx && userctx.setUser(null);
            return Promise.reject(error);
          });

        if (isRefleshSuccess) {
          console.log("リフレッシュ成功");
          originalRequest.headers["Authorization"] = "Bearer " + promise;
          var test = await axios(originalRequest);
          return test;
        }

        return promise;
      } else {
        return Promise.reject(error);
      }
    });

  return null;
}
