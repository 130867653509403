import { SwipeableDrawer, Toolbar } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  withCustomerList: {
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
    width: 700,
  },
  BtnRdiusClear: {
    "& button": {
      borderRadius: "unset",
    },
  },
}));

/**
 * ボタンを下部に固定した検索用ドロワーを返します。
 * @module FixButtonSearchDrawer
 * @param {Object} props
 * @param {string} props.open - ドロワーのOPEN
 * @param {string} props.setOpen - ドロワーのOPEN
 * @param {string} props.formId - 検索フォームのID
 * @param {string} props.onSubmit - 検索ボタン押下時処理
 * @param {string} props.children - 検索フォームの内容
 * @param {string} props.buttonGrops - ボタングループ
 * @param {boolean} props.customerWidth - 選択した得意先を表示するドロワーの表示非表示
 * @param {int} props.foremostZIndex - ドロワーを最前面に表示
 * @param {boolean} props.isNotToolbar - ドロワータイトルの上の余白の有無
 * @returns
 */
export default function FixButtonSearchDrawer(props) {
  const classes = useStyles();

  const {
    open,
    setOpen,
    formId,
    onSubmit,
    children,
    buttonGrops,
    customerWidth,
    foremostZIndex,
    isNotToolbar,
  } = props;

  return (
    <SwipeableDrawer
      keepMounted={true}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      style={{ zIndex: isNotToolbar ? foremostZIndex : 1200, maxWidth: "350px" }}
    >
      <form id={formId} onSubmit={onSubmit} style={{ height: "100%" }}>
        {!isNotToolbar && <Toolbar />}
        <div
          className={customerWidth ? classes.withCustomerList : classes.list}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 64px)",
          }}
        >
          <div style={{ overflow: "auto", height: "100%" }}>{children}</div>
          <div className={classes.BtnRdiusClear} style={{ width: 350 }}>
            {buttonGrops}
          </div>
        </div>
      </form>
    </SwipeableDrawer>
  );
}
