import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Fragment } from "react";
import { commonStyle } from "components/styles/CommonStyle";

import {
  Box,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { PagenationActions } from "components/table/PagenationTable";

const useStyles = makeStyles((theme, props) => ({
  pagenation: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& > .MuiPagination-ul": {
      flexWrap: "unset",
    },
  },
  table_pagenation: {
    "& > .MuiToolbar-root": {
      flexWrap: "wrap",
      "& > p": {
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
  },
  table: {
    // セル内改行を阻止
    "& > .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root": {
      whiteSpace: "nowrap",
    },
  },
}));

/**
 * @example
 *
 * @module TablePagenation
 */
export default function TablePagenation(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const styles = commonStyle();

  const {
    storageName,
    rowsPerPageOptions,
    rowsPerPageDefault,
    countDefault,
    pageDefault,
    searchFunction,
    requireHeader,
    requireFooter,
    refreshTable,
    isFirstSearch,
    head,
    body,
    isTableRowSpan,
    pagenationStyle,
  } = props;

  const preSessionPageNo = sessionStorage.getItem(storageName);
  const labelRowsPerPage = "rows";

  const [rowsPerPage, setRowsPerPage] = useSafeState(
    unmountRef,
    rowsPerPageDefault ? rowsPerPageDefault : 5
  );
  const [page, setPage] = useSafeState(
    unmountRef,
    preSessionPageNo ? Number(preSessionPageNo) : pageDefault ? pageDefault : 0
  );
  const [count, setCount] = useSafeState(unmountRef, countDefault ? countDefault : 0);
  const [dispHeader] = useSafeState(unmountRef, requireHeader === undefined ? true : requireHeader);
  const [dispFooter] = useSafeState(unmountRef, requireFooter === undefined ? true : requireFooter);
  const [firstSearched, setFirstSearched] = useSafeState(unmountRef, false);

  // ページ変更
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // ページ表示件数変更
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = (data) => {
    return `${data.from} - ${data.to === -1 ? data.count : data.to} of ${data.count}`;
  };

  function pagination(style) {
    return (
      <TablePagination
        className={style}
        rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={labelRowsPerPage}
        labelDisplayedRows={(data) => labelDisplayedRows(data)}
        style={pagenationStyle}
        ActionsComponent={PagenationActions}
      />
    );
  }

  const tablePagenation = () => {
    return (
      <>
        {/* xs以下はこっち*/}
        <Box className={styles.visibleXs}>{pagination(classes.table_pagenation)}</Box>
        {/* sm以上はこっち */}
        <Box className={styles.hiddenSmDown}>{pagination()}</Box>
      </>
    );
  };

  // 検索処理
  const Search = async () => {
    var response = await searchFunction(page * rowsPerPage, rowsPerPage);
    // あればレスポンスからカウントを取り出す。
    if (response) {
      if (response.headers["x-total-count"]) {
        setCount(Number(response.headers["x-total-count"]));
      }
    }
  };

  useEffect(() => {
    if (isFirstSearch) Search();
    setFirstSearched(true);
  }, []);

  useEffect(() => {
    if (firstSearched) Search();
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (firstSearched) {
      if (page !== 0) setPage(0);
      else Search();
    }
  }, [refreshTable]);

  useEffect(() => {
    if (firstSearched) sessionStorage.setItem(storageName, page);
  }, [page]);

  return (
    <>
      {dispHeader && tablePagenation()}
      <TableContainer
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
        component={Paper}
        elevation={0}
      >
        <Table stickyHeader aria-label="sticky table" className={classes.table} size="small">
          <TableHead>
            {isTableRowSpan === true ? <>{head}</> : <TableRow>{head}</TableRow>}
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </TableContainer>
      {dispFooter && tablePagenation()}
    </>
  );
}
