import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import React, { useEffect, useCallback, useContext } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Toolbar,
  Paper,
  Box,
  Input,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { commonStyle } from "components/styles/CommonStyle";
import ScroollToTopOnMount from "components/behavior/Scroll";
import TitleText from "components/text/TitleText";
import { useForm } from "react-hook-form";
import { ErrMsgKey, GetErrMsg, ErrMsg } from "components/common/ErrorMessageProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { FormDataToJson } from "components/common/FormHelper";
import { MessageContext } from "components/context/MessageContext";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
export default function ResetPassword(props) {
  const unmountRef = useUnmountRef();
  const classes = useStyles();
  const style = commonStyle();
  const theme = useTheme();
  const msgctx = useContext(MessageContext);
  const [token, setToken] = useSafeState(unmountRef, "");

  const [btnDisabled, setBtnDisabled] = useSafeState(unmountRef, false);

  //入力チェックのスキーマ定義
  const [schemaData, setSchemaData] = useSafeState(unmountRef, {
    id: yup.string().required(GetErrMsg(ErrMsgKey.required)),
    password: yup.string().required(GetErrMsg(ErrMsgKey.required)),
    confirmPassword: yup.string().required(GetErrMsg(ErrMsgKey.required)),
  });
  const schema = yup.object().shape(schemaData);

  //react-hooks-form
  const { register, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(schema), //react-hooks-formのValidationでyupを使用する際はこうする
  });

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setToken(params.get("code")); //トークンをhiddenに埋め込む
  });

  // submit時処理
  const onSubmit = useCallback(async function onSubmit() {
    setBtnDisabled(true);
    var values = FormDataToJson("reset-form");
    await axios
      .put("api/forgotpassword", values)
      .then(function (response) {
        if (response.status == 200) {
          msgctx.enqueue("パスワードを更新しました", "success");
          setTimeout(function () {
            props.history.push("/login");
          }, 3000); //3秒スリープ後リダイレクト
        }
        return response;
      })
      .catch(function (error) {
        msgctx.enqueue("パスワードを更新できませんでした", "error");
        setBtnDisabled(false);
      });
  });

  const boxSpacing = 1;
  return (
    <form
      id="reset-form"
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ScroollToTopOnMount />
      <Toolbar></Toolbar>
      <Container>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid item md={12}>
            <Paper style={{ padding: "30px", textAlign: "center" }}>
              <TitleText>パスワード再設定</TitleText>
              <EnhancedEncryptionIcon
                style={{ fontSize: 80, color: "#ddd", marginBottom: "20px" }}
              ></EnhancedEncryptionIcon>
              <Typography>以下の項目を入力してパスワードを再設定してください</Typography>
              <ErrMsg className={style.errMsg} errors={errors} name="summary" />
              {/* TODO EmailInputに変更する */}
              <Box my={boxSpacing}>
                <TextField name="id" label="ログインID" required inputRef={register} />
                <ErrMsg className={style.errMsg} errors={errors} name="id" />
              </Box>
              <Box my={boxSpacing}>
                <TextField
                  id="pwd"
                  name="password"
                  label="新しいパスワード"
                  type="password"
                  required
                  inputRef={register}
                />
                <ErrMsg className={style.errMsg} errors={errors} name="password" />
              </Box>
              <Box my={boxSpacing}>
                <TextField
                  id="pwd2"
                  name="confirmPassword"
                  label="新しいパスワード(確認)"
                  type="password"
                  required
                  inputRef={register}
                />
                <ErrMsg className={style.errMsg} errors={errors} name="confirmPassword" />
              </Box>

              <br />
              <Button
                style={{ marginTop: theme.spacing(1) }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={btnDisabled}
                onClick={() => {
                  clearErrors();
                }}
              >
                パスワード再設定
              </Button>
              <Input name="token" type="hidden" value={token} />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
}
