import React from "react";
import { Button, ButtonGroup, Divider, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ClearAll, Search, Close } from "@mui/icons-material";
import { BootstrapInput } from "components/input/textbox/BootstrapInput";
import FixButtonSearchDrawer from "components/drawer/FixButtonSearchDrawer";

/**
 * 得意先検索ドロワーとその表示ボタン
 * @module CustomerSearchButton
 * @example
    <CustomerSearchButton
      pStorageName={pStorageName}
      pIsSearchDrawerOpen={isSearchDrawerOpen}
      pSetIsSearchDrawerOpen={setIsSearchDrawerOpen}
      pFormId={formId}
      pSearchCustomers={SearchCustomers}
      pUseClearBtn={true}
    />
 * @param {bool} pIsSearchDrawerOpen - ドロワーの表示制御
 * @param {function} pSetIsSearchDrawerOpen - ドロワーの表示制御セッター
 * @param {string} pFormId - 検索フォームのID
 * @param {function} pSearchCustomers - 検索処理
 * @param {Object} pDrawerPaperStyle - ドロワーのペーパーのスタイル 未使用のため削除
 * @param {string} pStorageName - 検索値を保持したい場合は、保持するセッションストレージ名をセットする
 * @param {bool} pUseClearBtn - 検索条件をクリアするボタンを表示する
 */
const CustomerSearchButton = (props) => {
  const {
    pIsSearchDrawerOpen,
    pSetIsSearchDrawerOpen,
    pFormId,
    pSearchCustomers,
    pStorageName,
    pUseClearBtn,
  } = props;

  const preSearchItems = pStorageName && JSON.parse(sessionStorage.getItem(pStorageName));

  const theme = useTheme();
  const SearchItems = (
    <>
      <List>
        <ListItem>得意先検索</ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth={true}
            name="id"
            placeholder="得意先ID"
            defaultValue={preSearchItems && preSearchItems.id}
          />
        </ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth
            name="customerName"
            placeholder="得意先名"
            defaultValue={preSearchItems && preSearchItems.customerName}
          />
        </ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth
            name="customerName2"
            placeholder="得意先名2"
            defaultValue={preSearchItems && preSearchItems.customerName2}
          />
        </ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth
            name="salesPersonCode"
            placeholder="担当者コード"
            defaultValue={preSearchItems && preSearchItems.salesPersonCode}
          />
        </ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth
            name="customerAddress"
            placeholder="得意先住所"
            defaultValue={preSearchItems && preSearchItems.customerAddress}
          />
        </ListItem>
        <ListItem>
          <BootstrapInput
            fullWidth
            name="customerPhoneNumber"
            placeholder="得意先電話番号"
            defaultValue={preSearchItems && preSearchItems.customerPhoneNumber}
          />
        </ListItem>
      </List>
      <Divider />
      {pUseClearBtn && (
        <Button
          type="button"
          variant="outlined"
          color="error"
          onClick={() => {
            const elem = document.getElementById(pFormId);
            if (elem) {
              for (var idx = 0; idx < elem.elements.length; idx++) elem.elements[idx].value = "";
            }
            sessionStorage.removeItem(pStorageName);
          }}
          style={{
            marginTop: theme.spacing(1),
            borderRadius: "unset",
            width: "100%",
          }}
        >
          <ClearAll /> 内容をリセット
        </Button>
      )}
    </>
  );

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          pSetIsSearchDrawerOpen(true);
        }}
      >
        <Search /> 絞り込み
      </Button>

      {/* 検索ドロワー */}
      <FixButtonSearchDrawer
        open={pIsSearchDrawerOpen}
        setOpen={pSetIsSearchDrawerOpen}
        formId={pFormId}
        onSubmit={pSearchCustomers}
        buttonGrops={
          <ButtonGroup variant="contained" fullWidth>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                pSetIsSearchDrawerOpen(false);
              }}
            >
              <Close /> 閉じる
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => {
                pSearchCustomers();
                const sessionObj = {};
                const elem = document.getElementById(pFormId);
                if (elem) {
                  for (var idx = 0; idx < elem.elements.length; idx++)
                    sessionObj[elem.elements[idx].name] = elem.elements[idx].value;
                }
                sessionStorage.setItem(pStorageName, JSON.stringify(sessionObj));
              }}
            >
              <Search /> 検索
            </Button>
          </ButtonGroup>
        }
      >
        {SearchItems}
      </FixButtonSearchDrawer>
    </>
  );
};
export default CustomerSearchButton;
