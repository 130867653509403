import { useSafeState, useUnmountRef } from "components/common/UnmountStateHelper";
import { sessionStorageKeys } from "./Const";
import { useContext, useEffect } from "react";
import { MessageContext } from "components/context/MessageContext";
import axios from "axios";
import CheckRevision from "components/api/CheckRevisionApi";
import useTimer from "components/behavior/Timer";
import useReactRouter from "use-react-router";

/**
 * リビジョンをチェックし強制リロードorメッセージ表示します
 * pattern = 1:メッセージ表示 2:強制リロード
 * @param {Object} axiosInterCept {pattern:1} axiosのリクエストをインターセプトしてチェックします。
 * @param {Object} timer {pattern:1,ms:60000 }タイマーでチェックします。（例は一分）
 * @param {Object} init {pattern:1} 初期表示時にチェックします。
 * @returns 成功時はtrue エラー時はfalse を返却します
 */
export default function CheckRevisionComponent(props) {
  const unmountRef = useUnmountRef();
  const { axiosInterCept, timer, init } = props;
  const [isFirst, setIsFirst] = useSafeState(unmountRef, true);
  let axiosInterCepter;
  const { location } = useReactRouter();
  const msgctx = useContext(MessageContext);
  const time = useTimer(timer && timer["ms"] ? timer["ms"] : 60000);

  useEffect(() => {
    // サーバーバージョンを取得
    var serverVersion = sessionStorage.getItem(sessionStorageKeys.ServerRevision);
    // 画面リロード後メッセージがある場合
    var reload = sessionStorage.getItem(sessionStorageKeys.Reload);
    if (reload && serverVersion) {
      msgctx.enqueueNotClose(reload, "info");

      // バージョンを更新
      sessionStorage.setItem(sessionStorageKeys.Revision, serverVersion);

      // リロードメッセージを削除
      sessionStorage.removeItem(sessionStorageKeys.Reload);
      // サーバーバージョンを削除
      sessionStorage.removeItem(sessionStorageKeys.ServerRevision);
    }

    setIsFirst(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isFirst === false) {
      const fn = async () => {
        if (axiosInterCept && axiosInterCept["pattern"]) {
          // axiosのrequest前にリビジョンチェックする。

          axiosInterCepter = axios.interceptors.request.use(function (request) {
            let fn2 = async () => {
              await CheckRevision(msgctx, axiosInterCept["pattern"], () => {
                if (axiosInterCept["pattern"] === 2)
                  throw new axios.Cancel("バージョンに差異があるためリロードして処理キャンセル");
              });
            };
            fn2();

            return request;
          });
        }

        if (init && init["pattern"]) {
          // 初期表示時にチェックする。
          await CheckRevision(msgctx, init["pattern"], () => {
            console.log("init_error");
          });
        }
      };

      fn();
      setIsFirst(true);
    }

    return function cleanup() {
      if (axiosInterCepter !== undefined) {
        axios.interceptors.request.eject(axiosInterCepter);
      }
    };
  }, [isFirst]);

  useEffect(() => {
    if (!time || !(timer && timer["pattern"])) return;
    // タイマーでチェックする。
    const fn = async () => {
      await CheckRevision(msgctx, timer["pattern"], () => {
        console.log("init_error");
      });
    };
    fn();
  }, [time]);

  return null;
}
