import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback } from "react";
import useReactRouter from "use-react-router";
import clsx from "clsx";
import { ArrowBackIos, DeleteForever, Edit } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  buttonRadius: {
    borderRadius: "50px",
  },
  buttonBox: {
    paddingTop: "4px",
    textAlign: "center",
  },
}));

const editText = () => {
  return (
    <>
      <Edit /> 更新
    </>
  );
};
const deleteText = () => {
  return (
    <>
      <DeleteForever /> 削除
    </>
  );
};
const returnText = () => {
  return (
    <>
      <ArrowBackIos /> 戻る
    </>
  );
};
// Propsのデフォルト値
EditScreenButtons.defaultProps = {
  color1: "primary",
  color2: "secondary",
  color3: "inherit",
  // text1: "更新",
  text1: editText(),
  // text2: "削除",
  text2: deleteText(),
  // text3: "戻る",
  text3: returnText(),
  type1: "button",
  type2: "button",
  type3: "button",
  isDisable1: false,
  isDisable2: false,
  isDisable3: false,
  isUse1: true,
  isUse2: false,
  isUse3: true,
  isPaperInner: false,
};

/**
 * 編集画面用のボタンを返します。
 * （基本的に更新と戻るの想定）
 * @module EditScreenButtons
 * @example
 *
 * @param {Object} props - 残りの設定は両方のボタンに設定されます。
 * @param {function} onClick1 - ボタン1クリック時処理　初期値：なし
 * @param {function} onClick2 - ボタン2クリック時処理　初期値：なし
 * @param {function} onClick3 - ボタン3クリック時処理　初期値：history.goBack()
 * @param {string} color1 - ボタン1色　初期値：primary
 * @param {string} color2 - ボタン2色　初期値：secondary
 * @param {string} color3 - ボタン3色　初期値：inherit
 * @param {string} className1 - ボタン1クラス　初期値：なし
 * @param {string} className2 - ボタン2クラス　初期値：なし
 * @param {string} className3 - ボタン3クラス　初期値：なし
 * @param {string} text1 - ボタン1表示文言　初期値：更新
 * @param {string} text2 - ボタン2表示文言　初期値：削除
 * @param {string} text3 - ボタン3表示文言　初期値：戻る
 * @param {string} type1 - ボタン1タイプ　初期値：button
 * @param {string} type2 - ボタン2タイプ　初期値：button
 * @param {string} type3 - ボタン3タイプ　初期値：button
 * @param {bool} isDisable1 - ボタン1disable true or false　初期値：false
 * @param {bool} isDisable2 - ボタン2disable true or false　初期値：false
 * @param {bool} isDisable3 - ボタン3disable true or false　初期値：false
 * @param {bool} isUse1 - ボタン1を使うか true or false　初期値：true
 * @param {bool} isUse2 - ボタン2を使うか true or false　初期値：false
 * @param {bool} isUse3 - ボタン3を使うか true or false　初期値：true
 * @param {bool} isPaperInner - ManageMenuPaperコンポーネントを親に持つコンポーネント内で使用する場合、レイアウトを合わせたボタン配置にする true or false　初期値：false
 */
function EditScreenButtons(props) {
  const {
    onClick1,
    onClick2,
    onClick3,
    color1,
    color2,
    color3,
    className1,
    className2,
    className3,
    text1,
    text2,
    text3,
    type1,
    type2,
    type3,
    isDisable1,
    isDisable2,
    isDisable3,
    isUse1,
    isUse2,
    isUse3,
    isPaperInner,
    gridStyle,
    ...other
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { history } = useReactRouter();
  const btn3Click = (e) => {
    onClick3 ? onClick3(e) : history.goBack();
  };
  const btnCount = Number(isUse1 ? 1 : 0) + Number(isUse2 ? 1 : 0) + Number(isUse3 ? 1 : 0);
  const xsSize = btnCount <= 2 ? 6 : 12 / btnCount;
  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        spacing={2}
        style={gridStyle ? gridStyle : { marginTop: theme.spacing(1) }}
      >
        {!isPaperInner && isUse2 && (
          <Grid item xs={xsSize} sm={xsSize} md={xsSize} lg={xsSize}>
            <Button
              {...other}
              color={color2}
              type={type2}
              disabled={isDisable2}
              onClick={onClick2}
              className={clsx(className2, classes.buttonRadius)}
            >
              {text2}
            </Button>
          </Grid>
        )}

        {!isPaperInner && isUse3 && (
          <Grid item xs={xsSize} sm={xsSize} md={xsSize} lg={xsSize}>
            <Button
              {...other}
              color={color3}
              type={type3}
              disabled={isDisable3}
              onClick={btn3Click}
              className={clsx(className3, classes.buttonRadius)}
            >
              {text3}
            </Button>
          </Grid>
        )}

        {isUse1 && (
          <Grid
            item
            xs={isPaperInner ? 12 : xsSize}
            sm={isPaperInner ? 12 : xsSize}
            md={isPaperInner ? 11 : xsSize}
            lg={isPaperInner ? 9 : xsSize}
            className={isPaperInner ? classes.buttonBox : ""}
          >
            <Button
              {...other}
              color={color1}
              type={type1}
              disabled={isDisable1}
              onClick={onClick1}
              className={clsx(className1, classes.buttonRadius)}
            >
              {text1}
            </Button>
          </Grid>
        )}

        {isPaperInner && isUse2 && (
          <Grid item xs={12} sm={12} md={11} lg={9} className={classes.buttonBox}>
            <Button
              {...other}
              color={color2}
              type={type2}
              disabled={isDisable2}
              onClick={onClick2}
              className={clsx(className2, classes.buttonRadius)}
            >
              {text2}
            </Button>
          </Grid>
        )}

        {isPaperInner && isUse3 && (
          <Grid item xs={12} sm={12} md={11} lg={9} className={classes.buttonBox}>
            <Button
              {...other}
              color={color3}
              type={type3}
              disabled={isDisable3}
              onClick={btn3Click}
              className={clsx(className3, classes.buttonRadius)}
            >
              {text3}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default EditScreenButtons;
