import React from "react";
import withStyles from "@mui/styles/withStyles";
import InputBase from "@mui/material/InputBase";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& ~ p": {
      marginTop: "-15px",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    margin: "5px 5px 5px 0px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export function BootstrapInputWithLabel(props) {
  const { fullWidth, label, helperText, required, ...other } = props;

  //↓これ入れるとreact hook formsでyupバリデーション出来ない
  //元のままだとdefaultValueが設定できないためこの処理を加える

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel style={{ zIndex: 1000 }} shrink={true} required={required}>
        {label}
      </InputLabel>
      <BootstrapInput {...other}>{props.children}</BootstrapInput>
      {helperText && <FormHelperText margin="dense">{helperText}</FormHelperText>}
    </FormControl>
  );
}
