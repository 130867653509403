import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "pages/Layout";
import AuthorizeRoute from "components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "pages/apiauthorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import "react-app-polyfill/stable";
import "custom.css";

import Home from "pages/home/Home";
import LoginFormContainer from "pages/apiauthorization/LoginForm";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import ForgotConfirm from "pages/auth/ForgotConfirm";
import NotFound from "pages/shared/NotFound";
import { AuthLayout } from "pages/AuthLayout";
import Login2faForm from "pages/auth/Login2fa";
import UnauthorizeRedirector from "components/api-authorization/UnauthorizeRedirector";
export default class AppRouter extends Component {
  constructor(props) {
    super(props);
  }

  static displayName = AppRouter.name;

  render() {
    return (
      <Layout menuBtn={false}>
        <UnauthorizeRedirector></UnauthorizeRedirector>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={LoginFormContainer} />
          <Route path="/login2fa" component={Login2faForm} />
          <Route path="/auth/forgot" component={ForgotPassword} />
          <Route path="/auth/forgotConfirm" component={ForgotConfirm} />
          <Route path="/auth/reset" component={ResetPassword} />

          <AuthorizeRoute path="/mypage" component={AuthLayout} />
          <AuthorizeRoute path="/manage/" component={AuthLayout} />
          <AuthorizeRoute path="/product/" component={AuthLayout} />
          <AuthorizeRoute path="/stock/" component={AuthLayout} />
          <AuthorizeRoute path="/user/" component={AuthLayout} />
          <AuthorizeRoute path="/admin" component={AuthLayout} />
          <AuthorizeRoute path="/inquiry/" component={AuthLayout} />
          {/* <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          /> */}
          <Route path="/not-found" component={NotFound} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}
