import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";

export const ApiPostContactAsync = async (comment, values, token) => {
  let tkn = token;
  if (!tkn) tkn = await authService.getAccessToken();
  return await axios.post(`api/contacts/mail?userName=${values}`, comment, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
  });
};
